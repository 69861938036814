import { Settings } from 'app'
import Fetch from './Fetch'

export interface InstagramDataProps {
  caption?: string
  id: string
  media_type: Uppercase<'image' | 'video' | 'carousel_album'>
  media_url: string
  permalink: string
}
interface InstagramDataActions {
  getInstagramPosts(onSuccess: (data: InstagramDataProps[]) => void): Promise<void>
}

const url = `https://graph.instagram.com/me/media?access_token=${Settings.INSTAGRAM_API_TOKEN}&fields=media_url,media_type,caption,permalink&limit=9`

const getInstagramPosts: InstagramDataActions['getInstagramPosts'] = async (onSuccess) => {
  await Fetch({
    url,
    method: 'GET',
    options: {
      full_url: true,
    },
    onSuccess: (res) => {
      // log(res.data)
      onSuccess(res.data)
    },
  })
}

export default {
  getInstagramPosts,
}
