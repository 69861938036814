import { Theme, ViewStyles } from 'app'
import { RawStyleSheet } from 'types/style'
import StyleSheet from '../../lib/utils/StyleSheet'
export const textObject = {
  ...ViewStyles,
  // Typography
  default: {
    ...Theme.row,
    color: Theme.colors.greyscale10,
    textDecorationColor: Theme.colors.greyScale10,

    fontFamily: Theme.fontFamily,
  },
  fadeIn: {},
  h1: {
    // fontSize: "clamp(33px, calc((1.2vw+1.6px) * 6.3), 90px )",
    fontSize: 'max(32px, min(3.9vw, 82px))',
    // fontSize: "max(85px, min(4vw, 96px))",

    fontWeight: 700,

    letterSpacing: '-2',
    lineHeight: '0.9',
    // [Theme.media.down('mid')]: {
    //   letterSpacing: '-6',
    //   lineHeight: '0',
    // },
  },
  h2: {
    // fontSize: "clamp(32px,calc((1vw+1.6px) * 4.209),74px)",
    fontSize: 'max(30px, min(calc(22px + 1.8vw), 62px))',

    fontWeight: 700,

    letterSpacing: '-2',
    lineHeight: '1.25',
    // [Theme.media.down('tinyest')]: {
    //   letterSpacing: '-6',
    //   lineHeight: '0',
    // },
  },
  h3: {
    // fontSize: "clamp(32px, calc((1vw+1.6px) * 3.157), 48px)",
    fontSize: 'max(25px, min(calc(20px + 1.6vw), 54px))',
    fontWeight: 700,
    lineHeight: '1.25',
  },
  h4: {
    // fontSize: "clamp(24px, calc((1vw+1.6px) * 4.209),36px)",
    fontSize: 'max(22px, min(calc(14px + 0.8vw), 30px))',
    fontWeight: 700,
    lineHeight: '1.25',
  },
  h5: {
    // fontSize: "clamp(calc((1vw+1.6px) * 2.369))",
    fontSize: 'max(20px, min(calc(12px + 0.65vw), 28px))',
    fontWeight: 700,
  },
  h6: {
    fontSize: 'min(20px, min(calc(12px + 0.8vw), 26px))',
    fontWeight: 700,
  },
  p1: {
    lineHeight: '1.4',
    fontWeight: 500,

    fontSize: 'clamp(20px, calc((1vw+1.6px) * 1.2), 28px)',
    // fontSize: 'max(15px, min(calc(2.3px + 0.95vw), 18px))',
  },
  'p1:responsive': {
    lineHeight: '1.4',
    fontWeight: 500,
    fontSize: 'clamp(16px, calc((1vw+1.6px) * 1.2), 28px)',
    // fontSize: 'clamp(calc((1vw+1.6px) * 1.2))',
  },
  p2: {
    lineHeight: '1.6',
    fontWeight: 500,
    fontSize: 'clamp(16px, calc((1vw+1.6px) * 1.2), 22px)',
    // fontSize: 'max(12px, min(calc(6px + 0.5vw), 15px))',
  },
  p3: {
    lineHeight: '1.4',
    fontWeight: 400,
    fontSize: 'clamp(14px, calc((1vw+1.6px) * 1.1), 20px)',
    // fontSize: 'max(11px, min(calc(8.5px + 0.2vw), 12px))',
  },
  p4: {
    lineHeight: '1.4',
    fontWeight: 400,
    fontSize: 'clamp(12px, calc((1vw+1.6px) * 1.1), 18px)',
    // fontSize: 'max(11px, min(calc(8.5px + 0.2vw), 12px))',
  },
  p5: {
    lineHeight: '1.4',
    fontWeight: 400,
    fontSize: 'clamp(10px, calc((1vw+1.6px) * 1.1), 16px)',
    // fontSize: 'max(11px, min(calc(8.5px + 0.2vw), 12px))',
  },
  p6: {
    lineHeight: '1.4',
    fontWeight: 400,
    fontSize: 'clamp(8px, calc((1vw + 1.6px) * 1.1), 14px)',
    // fontSize: 'max(11px, min(calc(8.5px + 0.2vw), 12px))',
  },

  // Aditional styles
  inherit: {
    fontWeight: 'inherit',
    fontSize: 'inherit',
    color: 'inherit',
    display: 'inherit',
    textDecorationStyle: 'inherit',
    textDecorationColor: 'inherit',
    textDecorationLine: 'inherit',
  },
  veryBold: {
    fontWeight: '900 !important',
  },
  semiBold: {
    fontWeight: '600 !important',
  },
  bold: {
    fontWeight: 'bold !important',
  },
  normal: {
    fontWeight: 'normal !important',
  },
  regular: {
    fontWeight: '400 !important',
  },
  thin: {
    fontWeight: '300 !important',
  },
  veryThin: {
    fontWeight: '100 !important',
  },
  tall: {
    lineHeight: '1.6',
  },
  italic: {
    fontStyle: 'italic',
  },
  link: {
    textDecorationStyle: 'solid',
    // textDecorationColor: 'inherit',

    cursor: 'pointer !important',
    '&:hover': {
      // textDecorationStyle: 'solid !important',
      // textDecorationColor: 'inherit',
      textDecorationLine: 'underline !important',
    },
  },
  noDecoration: {
    textDecorationLine: 'none',
  },
  noWrap: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'inline-block',
    textOverflow: 'ellipsis',
  },
  multiline: {
    whiteSpace: 'pre-line',
  },
  underline: {
    textDecorationLine: 'underline',
  },
  hoverDecoration: {
    textDecorationLine: 'none',
    '&:hover': {
      textDecorationStyle: 'solid',
      // textDecorationColor: 'inherit',
      textDecorationLine: 'underline',
    },
  },
  underlineDecoration: {
    textDecorationLine: 'underline',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  withTransition: {
    transition: '500ms',
  },
  justifyWords: {
    textJustify: 'inter-word',
    textAlign: 'justify',
  },
  space: {
    paddingLeft: 4,
  },
  narrow: {
    letterSpacing: '-2',
  },

  // Colors
  white: {
    color: Theme.colors.white,
    textDecorationColor: Theme.colors.white,
  },
  greyscale1: {
    color: Theme.colors.greyscale1,
    textDecorationColor: Theme.colors.greyscale1,
  },
  greyscale2: {
    color: Theme.colors.greyscale2,
    textDecorationColor: Theme.colors.greyscale2,
  },
  greyscale3: {
    color: Theme.colors.greyscale3,
    textDecorationColor: Theme.colors.greyscale3,
  },
  greyscale4: {
    color: Theme.colors.greyscale4,
    textDecorationColor: Theme.colors.greyscale4,
  },
  greyscale5: {
    color: Theme.colors.greyscale5,
    textDecorationColor: Theme.colors.greyscale5,
  },
  greyscale6: {
    color: Theme.colors.greyscale6,
    textDecorationColor: Theme.colors.greyscale6,
  },
  light: {
    color: Theme.colors.light,
    textDecorationColor: Theme.colors.light,
  },
  alert: {
    color: Theme.colors.redStatus,
    textDecorationColor: Theme.colors.redStatus,
  },

  debug: {
    ...Theme.debug('rgba(50, 100, 255, 0.5)'),
  },
} as const
// @ts-ignore
export const text = StyleSheet.createVariants(textObject)

export const compatibilityObject = {
  ...textObject,
  // Typography

  h1: {
    fontSize: '2.5rem',
  },
  h2: {
    fontSize: '2rem',
  },
  h3: {
    fontSize: '1.5rem',
  },
  h4: {
    fontSize: '1.3rem',
  },
  h5: {
    fontSize: '1.2rem',
  },
  h6: {
    fontSize: '1.2rem',
  },
  p1: {
    fontSize: '1.2rem',
  },
  p2: {
    fontSize: '1.1rem',
  },
  p3: {
    fontSize: '1rem',
  },
  p4: {
    fontSize: '0.9rem',
  },
}

// @ts-ignore
const compatibility = StyleSheet.createVariants(compatibilityObject)

export default {
  text,
  textObject,
  compatibility,
}
