import { Marker } from '@react-google-maps/api'
import { React, useRedux } from 'lib'
import { Map } from 'actions'
import { Theme } from 'app'
import { MarkerProps } from '.'
import { RouteEvent } from 'types/ApiTypes'
import { MARKER_SIZE } from './utils'

import userPin from 'assets/userPin.png'
import viewerPin from 'assets/viewerPin.png'
import userPinDisabled from 'assets/userPinDisabled.png'

export const UserMarker: React.FC<MarkerProps & {data: RouteEvent}> = ({ lat, lng, data, self = false }) => {
  const isSmall = Theme.hooks.down('small')
  const { route } = useRedux('LiveTracking')

  const inactiveTracking = route?.active === false

  function markerPress(event: google.maps.MapMouseEvent) {
    if (self && isSmall) return
    const detailsData = {
      ...data,
      coords: {
        lat,
        lng,
      },
    }
    if (self) {
      detailsData.viewerLocation = true
    } else {
      detailsData.currentLocation = true
    }
    Map.triggerPress(detailsData, isSmall)
  }

  const icon = self ? viewerPin : inactiveTracking ? userPinDisabled : userPin

  return (
    <Marker
      position={{ lat, lng }}
      onClick={markerPress}
      icon={{
        anchor: new window.google.maps.Point(31, 38),
        url: icon,
        scaledSize: {
          height: MARKER_SIZE.user,
          width: MARKER_SIZE.user,
          equals: null,
        },
      }}
    />
  )
}
