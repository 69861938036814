import { ViewStyles } from 'app'
import { Theme } from '../'
import StyleSheet from '../../lib/utils/StyleSheet'
const styles = StyleSheet.createVariants({
  ...ViewStyles,
  default: {
    display: 'inline-flex',
    cursor: 'pointer',
    textDecoration: 'none',
    transition: '300ms',
  },
  grow: {
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  growSmall: {
    transition: '100ms',
    '&:hover': {
      transform: 'scale(1.01)',
    },
  },
  plain: {
    cursor: 'unset',
    display: 'unset',
    textDecorationStyle: 'unset',
  },
  deep: {
    '&:active': {
      transform: 'scale(0.985)',
      transition: '120ms',
    },
    transition: '120ms',
  },
  disabled: {
    cursor: 'not-allowed',
    opacity: 0.7,
  },
  icon: {
    '& > svg': {
      width: '2em',
      height: '2em',
      color: Theme.colors.white,
      fill: Theme.colors.white,
      stroke: Theme.colors.white,
    },
  },
  IconDark: {
    '& > svg': {
      width: '2em',
      height: '2em',
      color: Theme.colors.greyscale10,
      fill: Theme.colors.greyscale10,
      stroke: Theme.colors.greyscale10,
    },
  },
})

export default styles
