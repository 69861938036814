import { Marker } from '@react-google-maps/api'

import { React } from 'lib'
import { MarkerProps } from '.'

import selectedCoordsMarker from 'assets/selected_coords_icon.png'
import { MARKER_SIZE } from './utils'

export const SelectedCoordsMarker: React.FC<MarkerProps> = ({ lat, lng }) => {
  return (
    <Marker
      position={{ lat, lng }}
      icon={{
        url: selectedCoordsMarker,
        anchor: new window.google.maps.Point(5, 5),
        scaledSize: {
          height: MARKER_SIZE.selectedCoords,
          width: MARKER_SIZE.selectedCoords,
          equals: null,
        },
      }}
    />
  )
}
