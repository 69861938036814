import {
  View,
  StyleSheet,
  React,
  Link,
  Touchable,
  useState,
  CenterWrapper,
  useScrollEffect,
  onUpdate,
} from 'lib'
import { Theme } from 'app'
import { Logo } from './Logo'
import { MenuIcon, CloseIcon } from '../assets/icons'
import { CSSProperties } from 'react'
import { LinkProps } from 'lib/ui/Link'
import { useLocation } from 'react-use'
const links = [
  {
    text: 'Home',
    to: '/',
  },
  {
    text: 'How Becon Works',
    to: '/#how-it-works',
  },
  {
    text: 'Plans & Pricing',
    to: '/becon+',
  },
  {
    text: 'News',
    to: '/news',
  },
  {
    text: 'Guidance',
    to: '/guidance',
  },
  {
    text: 'FAQ',
    to: '/faq',
  },
  {
    text: 'Contact Us',
    to: '/contact',
  },
]
const mobileLinkVariants: LinkProps['textVariant'] = ['p2']
export const Header: CodeleapHeader = ({ alwaysDark }) => {
  const [isMenuOpen, setMenu] = useState(false)
  const [below, setBelow] = useState(false)
  useScrollEffect(setBelow, Theme.values.maxHeaderMenuHeight)
  const location = useLocation()

  onUpdate(() => {
    if (location.hash) {
      onAnchorClick(`${location.hash}`)
    }
  }, [location])

  function toggleMenu() {
    setMenu(!isMenuOpen)
  }

  function onAnchorClick(target: string) {
    const elementToScroll = document.getElementById(target)
    if (elementToScroll) {
      elementToScroll.scrollIntoView({ behavior: 'smooth' })
      window.location.hash = target
    }
    if (isMenuOpen) {
      setMenu(false)
    }
  }
  const isMobile = Theme.hooks.down('mid')
  const menuStyle = isMobile
    ? {
      transform: `translateX(${isMenuOpen ? '0' : '-103%'})`,
    }
    : {}
  const overlayStyle: CSSProperties = {
    pointerEvents: isMenuOpen ? 'all' : 'none',
    opacity: isMenuOpen ? 1 : 0,
  }
  return (
    <CenterWrapper
      contentContainerStyle={styles.wrapper}
      style={[styles.outerWrapper, alwaysDark && styles.alwaysDark]}
    >
      <View
        variant={['absolute', 'whole']}
        style={{
          background: Theme.colors.greyscale10,
          opacity: below ? 1 : 0,
          transition: 'opacity 0.3s ease',
          zIndex: -1,
        }}
      />

      <Link to={'/'}>
        <Logo
          style={styles.logo}
          onClick={() => window.scroll({
            top: 0,
            behavior: 'smooth',
          })
          }
        />
      </Link>
      <View down={'mid'}>
        <Touchable onPress={toggleMenu} variant={['icon']}>
          <MenuIcon/>
        </Touchable>
      </View>
      <View
        variant={['fullViewWidth', 'fixed', 'overlay', 'whole']}
        style={{
          ...styles.menuOverlay,
          ...overlayStyle,
        }}
        onClick={toggleMenu}
      >
      </View>
      <View Element={'nav'} style={{ ...styles.nav, ...menuStyle }}>
        <View
          down={'mid'}
          variant={[
            'flexNoFill',
            'row',
            'justifySpaceBetween',
            'alignCenter',
          ]}
          style={{
            height: Theme.values.headerMenuHeight,
          }}
        >
          <Link to={'/'}>
            <Logo style={styles.logo}/>
          </Link>
          <Touchable onPress={toggleMenu} variant={'icon'}>
            <CloseIcon/>
          </Touchable>
        </View>
        {links.map(({ text, to }, key) => {
          const pathname = location?.pathname?.split('/')[1]
          const headerStyleVerification = to?.split('/')[1] === pathname

          return (
            <Link
              key={key}
              text={text}
              style={[styles.navLink, headerStyleVerification && styles.active]}
              to={to}
              textVariant={isMobile ? mobileLinkVariants : ['p3', 'white']}
            />
          )
        })}
      </View>
    </CenterWrapper>
  )
}

const styles = StyleSheet.createComponentStyleSheet({
  outerWrapper: {
    height: Theme.values.maxHeaderMenuHeight,
    background: Theme.colors.headerGradient,
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 10,
  },
  alwaysDark: {
    background: Theme.colors.greyscale1,
    opacity: 1,
  },
  wrapper: {
    ...Theme.paddingVertical(2),
    ...Theme.paddingHorizontal(1),
    ...Theme.row,
    ...Theme.justifySpaceBetween,
    ...Theme.alignCenter,

    flex: 1,
  },
  logo: {
    height: Theme.values.maxHeaderMenuHeight * 0.6,
    width: 'auto',
    flexShrink: 0,
    marginLeft: Theme.spacing(1),
  },
  nav: {
    marginLeft: 'auto',
    [Theme.media.down('mid')]: {
      marginLeft: 0,
      backgroundColor: Theme.colors.greyscale1,
      position: 'fixed',
      zIndex: 2,
      left: 0,
      top: 0,
      bottom: 0,
      right: '11%',
      color: Theme.colors.greyscale10,
      display: 'flex',
      ...Theme.column,
      transition: 'transform 0.3s ease',
      ...Theme.paddingHorizontal(2),
      ...Theme.paddingVertical(2),
    },
  },
  navLink: {
    paddingBottom: Theme.spacing(0.5),
    borderBottom: '3px solid transparent',
    '&:hover': {
      textDecorationLine: 'none !important',
      borderBottomColor: '#FFF',

    },
    ...Theme.marginHorizontal(2),
    [Theme.media.down('mid')]: {
      color: Theme.colors.white,
      ...Theme.marginVertical(3),
      '&:hover': {
        borderBottomColor: Theme.colors.white,
      },
    },
  },
  active: {
    borderBottomColor: '#FFF',
    [Theme.media.down('mid')]: {
      borderBottomColor: Theme.colors.white,
    },
  },
  menuOverlay: {
    transition: '0.3s',

    zIndex: 1,
  },
})
