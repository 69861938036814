import { Settings, Theme } from 'app'
import { triggersList } from 'components'
import { useRedux } from 'lib'
import { ALertType, RouteEvent } from 'types/ApiTypes'
import { store } from '../reducers'
import Fetch from './Fetch'

type LiveTrackingActions = {
  getRouteHistory: (code: string, onSuccess?: () => void, onFailure?: (param: string) => void) => void;
}

function setProfileData(data) {
  store.dispatch({ type: 'SET_PROFILE_DATA', data })
}

function setCurrentLocation(data) {
  store.dispatch({ type: 'SET_CURRENT_LOCATION', data })
}

function setRouteData(data) {
  store.dispatch({ type: 'SET_ROUTE_DATA', data })
}

function setUserRoute(data) {
  store.dispatch({ type: 'SET_USER_ROUTE', data })
}

function setRouteEvents(data) {
  store.dispatch({ type: 'SET_ROUTE_EVENTS', data })
}

async function getW3WByCoords({ lat, lng }) {
  let response
  await Fetch({
    url: `https://api.what3words.com/v3/convert-to-3wa?coordinates=${lat}%2C${lng}&key=${Settings.W3W_TOKEN}`,
    options: { full_url: true, json: true },
    method: 'GET',
    headers: {
      'X-Api-Key': Settings.W3W_TOKEN,
    },
    onSuccess: (res) => {
      response = res
    },
  })
  return response
}

const getRouteHistory: LiveTrackingActions['getRouteHistory'] = async (code, onSuccess, onFailure) => {
  await Fetch({
    url: `history/?code=${code}`,
    method: 'GET',
    options: { json: true },
    onSuccess: async (res: { history: Array<{[x:string]: any}[]>, route: {} }) => {
      if (res?.history) {
        const history = res?.history
        const flatHistoryList = history?.reduce((prev, data) => [...prev, ...data], [])
        const userRoute = formatUserRoute(history)
        const events = await formatEvents(flatHistoryList)
        setProfileData({ ...flatHistoryList[0]?.profile, phone: res.profile.phone })
        setCurrentLocation({ lat: flatHistoryList[flatHistoryList.length-1].coords.lat, lng: flatHistoryList[flatHistoryList.length-1].coords.lng })
        setUserRoute(userRoute)
        setRouteEvents(events)
      }

      setRouteData(res?.route)
      onSuccess?.()
    },
    onFailure: async (err: Response) => {
      err.text().then(errTxt => {
        const message = getHistoryError(errTxt.replace(/["]/g, ''))
        onFailure(message)
      })
    },
  })
}

function getHistoryError(err: string) {
  switch (err) {
    case 'Verification key expired.':
      return 'expired'
    case 'Verification key disabled.':
      return 'disabled'
    case 'Route inactive.':
      return 'inactive'
    default:
      return 'not found'
  }
}

async function formatEvents(history) {
  return await Promise.all(history?.map(async (data, idx) => {
    const prevEvent = history[idx - 1]?.events[0]
    const alerts = data?.events?.reduce((prev, vl) => {
      const getRouteStatus = () => {
        if (vl?.alert_sent) {
          return prevEvent?.alert_sent !== vl?.alert_sent && vl?.alert_sent
        } else if (vl?.route_status) {
          return prevEvent?.route_status !== vl?.route_status && vl?.route_status
        }
      }

      return {
        ...prev,
        alertNotice: prevEvent?.alert_notice !== vl?.alert_notice && vl?.alert_notice,
        routeStatus: getRouteStatus(),
        routeUpdate: prevEvent?.route_update !== vl?.route_update && vl?.route_update,
      }
    }, {})

    const w3w = await getW3WByCoords(data?.coords)

    return {
      events: alerts,
      coords: data?.coords,
      w3w,
      created_datetime: data?.created_datetime,
      speed: data?.speed,
      accuracy: data?.accuracy,
      heading: data?.heading,
      batteryLevel: Number(data?.batteryLevel),
    }
  }))
}

function formatUserRoute(history) {
  return history?.map((track) => {
    return track.reduce((prev, position, idx, arr) => {
      let disconnected = position?.events?.find(event => event?.route_update === 'disconnected')?.route_update
      let paused = position?.events?.find(event => event?.route_status === 'PS')?.route_status
      const sosTriggered = position?.events?.find(event => event?.route_status === 'SO')?.route_status

      if (disconnected) {
        const disc = arr[idx + 1]?.events?.find(e => e?.route_update === 'reconnected')?.route_update
        disconnected = disc || false
      } else if (paused) {
        const up = arr[idx + 1]?.events?.find(e => e?.route_status === 'UP')?.route_status
        paused = up || false
      }

      return {
        coords: [...prev?.coords, position.coords],
        disconnected: prev?.disconnected || disconnected || false,
        paused: prev?.paused || paused || false,
        sosTriggered: prev?.sosTriggered || sosTriggered || false,
      }
    }, { coords: [], disconnected: false, paused: false, sosTriggered: false })
  })
}

function getTriggerColor(type: ALertType) {
  switch (type) {
    case 'alert':
      return Theme.colors.redStatus
    case 'warning':
      return Theme.colors.yellowAlert
    case 'neutral':
      return Theme.colors.greyAlert
    case 'active':
      return Theme.colors.greenStatus
    default:
      return Theme.colors.greyAlert
  }
}

function useTrigger(data?: RouteEvent) {
  const alert = data || useRedux('Map')?.triggerDetails

  const events = alert?.events || alert?.data?.events
  const event = events?.alertNotice || events?.routeStatus || events?.routeUpdate
  const trigger = triggersList[event] as typeof triggersList[keyof typeof triggersList]

  return { trigger, event }
}

const defaultData = {
  triggers: {
    viewerLocation: {
      title: 'Your location',
      description: 'This is your current location',
    },
    currentLocation: {
      title: 'Latest location',
      description: 'This is the current user location',
    },
    locationAnyData: {
      title: 'Location',
      description: null,
    },
  },
}

export default {
  setProfileData,
  getRouteHistory,
  getTriggerColor,
  useTrigger,
  defaultData,
}
