import { Theme } from 'app'
import { RawStyleSheet } from 'types/style'
import { layout, displayTypes } from '../Theme/contants'
import StyleSheet from '../../lib/utils/StyleSheet'
const styles = {
  //
  // Display types
  //
  default: {},
  ...displayTypes,
  //
  // Layout
  //
  ...layout,
  overlay: {
    backgroundColor: '#000b',
  },
  white: {
    backgroundColor: Theme.colors.white,
  },
  //
  // Other
  //
  debug: {
    ...Theme.debug('rgba(255, 0, 0, 1)'),
  },
  debugOrange: {
    ...Theme.debug('rgba(255, 200, 0, 1)'),
  },
  debugGreen: {
    ...Theme.debug('rgba(100, 200, 0, 1)'),
  },
}

function initStyles() {
  const sizes = 20
  const temp = {}
  function makeVariant(name, main, types, units = '', multiplier = 1) {
    for (let i = 1; i <= sizes; i++) {
      const key = `${name}:${i}`
      for (const t in types) {
        const val = Theme.spacing(i * multiplier)
        temp[key] = {
          ...temp[key],
          [`${main}${types[t]}`]: units ? `${val}${units}` : val,
        }
      }
    }
  }

  function addVariant(rootName) {
    makeVariant(`${rootName}`, `${rootName}`, [
      `Top`,
      `Bottom`,
      `Left`,
      `Right`,
    ])
    makeVariant(`${rootName}Top`, `${rootName}`, [`Top`])
    makeVariant(`${rootName}Bottom`, `${rootName}`, [`Bottom`])
    makeVariant(`${rootName}Left`, `${rootName}`, [`Left`])
    makeVariant(`${rootName}Right`, `${rootName}`, [`Right`])
    makeVariant(`${rootName}Vertical`, `${rootName}`, [`Top`, `Bottom`])
    makeVariant(`${rootName}Horizontal`, `${rootName}`, [`Left`, `Right`])
  }

  addVariant('padding')
  addVariant('margin')
  makeVariant(`maxWidth`, `max`, ['Width'], 'px', 20)
  makeVariant(`minWidth`, `min`, ['Width'], 'px', 20)

  return StyleSheet.createVariants({ ...styles, ...temp })
}
const variants = initStyles()
export default variants
