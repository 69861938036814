/* eslint no-restricted-imports: 'off' */
import { useState } from 'react'
import { useWindowResize } from 'beautiful-react-hooks'
import { Theme } from 'app'
import { AppTheme } from '../../_app/Theme/types'
import { breakpoints } from '../../_app/Theme/contants'
type BreakPoint = keyof AppTheme['breakpoints']
type MediaQueryParam = BreakPoint | number

export const queries = {
  up: (test: MediaQueryParam) => {
    // Upwards of... (excluding)
    let min = null
    if (typeof test === 'string') {
      const bkpts = breakpoints
      min = bkpts[test] + 1
    } else {
      min = test
    }
    const mediaString = `@media screen and (min-width:${min}px)`
    return mediaString
  },
  down: (test: MediaQueryParam) => {
    // Downwards of... (excluding)
    let max = null
    if (typeof test === 'string') {
      const bkpts = breakpoints
      max = bkpts[test]
    } else {
      max = test
    }
    const mediaString = `@media screen and (max-width:${max}px)`
    return mediaString
  },
  is: (test: BreakPoint) => {
    // Is media... (exact)
    const bkpts = breakpoints
    const idx = Object.keys(bkpts).indexOf(test)
    const values = Object.values(bkpts)
    const min = bkpts[test]
    const max = idx < values.length ? values[idx + 1] : values[values.length]
    const mediaString = `@media screen and (min-width:${min}px)${max ? ` and (max-width:${max}px)` : ''
    }`
    return mediaString
  },
  not: (test: BreakPoint) => {
    // Is NOT media... (exact)
    const bkpts = breakpoints
    const idx = Object.keys(bkpts).indexOf(test)
    const values = Object.values(bkpts)
    const min = bkpts[test]
    const max = idx < values.length ? values[idx + 1] : values[values.length]
    const mediaString = `@media not screen and (min-width:${min}px)${max ? ` and (max-width:${max}px)` : ''
    }`
    return mediaString
  },
}

export const renderToPlatformQuery = (props) => {
  let mediaString = ''
  if (props?.is) {
    mediaString = queries.not(props.is)
  } else if (props?.not) {
    mediaString = queries.is(props.not)
  } else if (props?.up) {
    mediaString = queries.down(props.up)
  } else if (props?.down) {
    mediaString = queries.up(props.down)
  }
  return mediaString
    ? {
      [`${mediaString}`]: {
        display: 'none',
      },
    }
    : {}
}

export const shouldRenderToPlatform = (props) => {
  let res = true
  if (props?.is) {
    res = hooks.is(props.is)
  } else if (props?.not) {
    res = hooks.not(props.not)
  } else if (props?.up) {
    res = hooks.up(props.up)
  } else if (props?.down) {
    res = hooks.down(props.down)
  }
  return res
}

export function getCurrentMediaSize() {
  const bkpts = breakpoints
  const keys = Object.keys(bkpts)
  const values = Object.values(bkpts)
  try {
    const width = (window.screen.width < window.innerWidth ? window.screen.width : window.innerWidth) - 1
    for (const v in values) {
      if (width < values[v]) {
        return keys[Number(v) - 1]
      }
    }
    return keys[keys.length - 1]
  } catch (err) {
    warn({ err })
    return keys[0]
  }
}

export function getCurrentSize() {
  try {
    return {
      width: window.screen.width < window.innerWidth ? window.screen.width : window.innerWidth,
      height: window.screen.height < window.innerHeight ? window.screen.height : window.innerHeight,
    }
  } catch (err) {
    warn({ err })
    return {
      width: 0,
      height: 0,
    }
  }
}

export const hooks = {
  size: function () {
    const [size, setSize] = useState(getCurrentSize())
    useWindowResize(() => {
      const currentSize = getCurrentSize()
      // NOTE this attempts to lock the device height in small screen sizes so that expanding/collapsing browser bar
      // doens't mess with parallax
      // log({ size, currentSize })
      if (size.width > breakpoints.small || size.width != currentSize.width) {
        setSize(currentSize)
        // Toast.info('Window size changed')
      }
    })
    return size
  },
  media: function () {
    const [mediaSize, setMediaSize] = useState(() => getCurrentMediaSize())
    useWindowResize(() => {
      // Toast.info('Trigger media change')
      const newMedia = getCurrentMediaSize()
      if (newMedia != mediaSize) {
        // Toast.info('Resize media')
        setMediaSize(newMedia)
      }
    })
    return mediaSize
  },
  is: function (test: BreakPoint) {
    const value = this.media()
    return value == test
  },
  not: function (test: BreakPoint) {
    const value = this.media()
    return value != test
  },
  up: function (test: BreakPoint) {
    const { width } = this.size()
    return width > breakpoints[test]
  },
  down: function (test: BreakPoint) {
    const { width } = this.size()
    return width <= breakpoints[test]
  },
}

export default {
  hooks,
  queries,
  shouldRenderToPlatform,
  getCurrentMediaSize,
  renderToPlatformQuery,
  getCurrentSize,
}
