/** @jsx jsx */
import { jsx } from "@emotion/react"

import { React, getStyles, Media } from "lib"
import { ViewStyles } from "app"
import { CSSInterpolation } from "types/style"
import { CSSProperties } from "react"

/**
 * @exports <View/>
 * Default View component. Use this instead of divs.
 *
 * @prop {string} variant ---> View style variants
 * @prop {object} style ---> View styles object
 * @prop {function} onHover ---> Triggered when hovered, returns bool
 * @prop {string} is ---> Shows View only on media size
 * @prop {string} not ---> Hides View only on media size
 * @prop {string} up ---> Shows View on upwards of media size
 * @prop {string} down ---> Shows View on downwards of media size
 *
 */
const styleVariants = { ViewStyles }
type AcceptedVariants = NestedKeys<typeof styleVariants>

type DivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

type ViewProps = DivProps & {
  variant?: string | AcceptedVariants[]
  debug?: boolean
  is?: string
  not?: string
  up?: string
  down?: string
  ignoreWarnings?: boolean
  style?: CSSInterpolation | CSSInterpolation[] | Partial<CSSProperties>
  onHover?: (hovering: boolean) => void
  media?: Parameters<typeof Media.renderToPlatformQuery>[0]
  Element?: React.ElementType
}

const ViewComponent: React.ForwardRefRenderFunction<HTMLDivElement, ViewProps> =
  (rawProps, ref) => {
    const {
      is,
      not,
      up,
      down,
      onHover,
      style,
      children,
      media,
      ignoreWarnings,
      Element = "div",
      ...props
    } = rawProps

    let { variant } = rawProps

    const platformMediaQuery = Media.renderToPlatformQuery({ is, not, up, down })
    const shouldRenderToPlatform = Media.shouldRenderToPlatform({ is, not, up, down })

    if (props.debug) {
      log({ rawProps, props, shouldRenderToPlatform, platformMediaQuery })
    }
    if (!shouldRenderToPlatform) return null

    const handleHover = (hover) => {
      if (onHover) onHover(hover)
    }

    if (props.debug) {
      variant = variant + " debug"
    }

    const styles = getStyles(variant, styleVariants)

    const divStyles = [styles.ViewStyles, style, platformMediaQuery]

    if (props.debug) {
      log({ rawProps, props, divStyles })
    }

    return (
      <Element
        {...props}
        ref={ref}
        css={divStyles}
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
      >
        {children}
      </Element>
    )
  }

const View = React.forwardRef(ViewComponent)

// ViewComponent.whyDidYouRender = Settings.WARN_WHY_DID_YOU_RENDER

export default View
