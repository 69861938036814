/* eslint global-require: 'off', no-restricted-imports: 'off' */

import { Settings } from 'app'
import { React, Logger, Analytics, Cookies } from 'lib'
import wrapWithProvider from './rootWrapperProvider'
import { Session } from 'actions'

export const wrapRootElement = wrapWithProvider

export const onClientEntry = () => {
  log('Gatsby launch')
  const isStaff = Cookies.get('Session.isStaff') == 'true'
  if (Settings.IS_DEVELOPMENT) {
    log('Development mode')
    if (Settings.WARN_WHY_DID_YOU_RENDER) {
      log({ WARN_WHY_DID_YOU_RENDER: Settings.WARN_WHY_DID_YOU_RENDER })
      const whyDidYouRender = require('@welldone-software/why-did-you-render')
      whyDidYouRender(React, {
        trackAllPureComponents: false,
        logOnDifferentValues: false,
        trackExtraHooks: [[require('react-redux/lib'), 'useSelector']],
      })
    }
    if (Settings.GOOGLE_ANALYTICS_ID && !isStaff) {
      Analytics.init(Settings.GOOGLE_ANALYTICS_ID, true) // NOTE test mode
    }
  } else {
    if (Settings.GOOGLE_ANALYTICS_ID && !isStaff) {
      Analytics.init(Settings.GOOGLE_ANALYTICS_ID)
    }
    log('Production build')
  }
  setTimeout(() => {
    Session.init()
  }, 5000)
}
