import { React } from 'lib'
import { PauseIcon, SpeedometerIcon, OffRouteIcon, StopIcon, NoSignalIcon, SignalIcon, AlertIcon } from 'assets/icons'
import { Alerts, ALertType, RouteUpdate, Status } from 'types/ApiTypes'
import { UnPauseIcon } from 'assets/icons'

type TriggerListType = {
  [x in Alerts | Status | RouteUpdate]: {
    title: string
    icon: React.ReactNode
    description: string
    show: boolean
    type: ALertType
  }
}

export const triggersList: TriggerListType = {
  PS: {
    title: 'Paused Journey',
    icon: PauseIcon,
    description: 'The user paused Becon.',
    show: true,
    type: 'neutral',
  },
  UP: {
    title: 'Unpaused Journey',
    icon: UnPauseIcon,
    description: 'The user unpaused Becon.',
    show: true,
    type: 'active',
  },
  HS: {
    title: 'Stopped Moving',
    icon: StopIcon,
    description: 'The user stopped moving for an unusual period of time.',
    show: false,
    type: 'alert',
  },
  SS: {
    title: 'Stopped Moving',
    icon: StopIcon,
    description: 'The user stopped moving for an unusual period of time.',
    show: true,
    type: 'alert',
  },
  IN: {
    title: 'Disconnected',
    icon: NoSignalIcon,
    description: 'The user went offline for an extended period of time.',
    show: false,
    type: 'alert',
  },
  disconnected: {
    title: 'Disconnected',
    icon: NoSignalIcon,
    description: 'The user went offline for an extended period of time.',
    show: true,
    type: 'neutral',
  },
  reconnected: {
    title: 'Reconnected',
    icon: SignalIcon,
    description: 'The user came back online after being disconnected.',
    show: true,
    type: 'active',
  },
  OR: {
    title: 'Off Route',
    icon: OffRouteIcon,
    description: 'The user deviated from their route by a significant distance.',
    show: true,
    type: 'alert',
  },
  TF: {
    title: 'High Speed',
    icon: SpeedometerIcon,
    description: 'The user started moving at an unusually high speed.',
    show: true,
    type: 'alert',
  },
  SO: {
    title: 'SOS Activated',
    icon: AlertIcon,
    description: 'The user manually activated SOS mode on their device.',
    show: true,
    type: 'alert',
  },
}
