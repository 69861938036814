import { Text, React, Touchable, getStyles } from 'lib'
import { ButtonStyles, Theme } from 'app'
import { FunctionComponent, ReactNode } from 'react'
import { CSSInterpolation } from 'types/style'
import { TouchableProps } from './Touchable'

/**
 * @exports <Button/>
 * Use this to add default UI buttons. For other touchable things, use Touchable instead. Receives the following props:
 *
 * @prop {string} text ---> Button content
 * @prop {string} icon ---> React icon to render
 * @prop {string} variant ---> Style variants
 * @prop {string} to ---> Go to URL when pressed (best for navigation)
 * @prop {function} onPress ---> Execute when pressed (best for triggers)
 * @prop {object} style ---> Button wrapper styles
 * @prop {object} textStyle ---> Button text styles
 *
 */

type AcceptedVariants = NestedKeys<typeof ButtonStyles>
type NativeButtonProps = Omit<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  'ref'
>
type IconProps = {
  content: FunctionComponent<any>
  color?: string
  side?: 'left' | 'right'
  size?: number
}
type ButtonProps = NativeButtonProps & {
  variant?: string | AcceptedVariants[]
  text?: string
  msg?: string
  style?: CSSInterpolation
  textStyle?: CSSInterpolation
  icon?: IconProps
  loading?: boolean
  onPress?: any
  to?: string
  openNewTab?: boolean
  ref?: React.Ref<any>
}

const ButtonIcon: React.FC<{
  IconProp: ButtonProps['icon']
  isSmall: boolean
}> = ({ IconProp, isSmall }) => {
  const color = IconProp?.color || Theme.colors.primary
  const size = IconProp?.size || (isSmall ? 24 : 30)
  const keyString = `button-icon-${size}-${color}-${IconProp.side || ''}`
  const Component = IconProp.content

  return <Component key={`${keyString}`} size={size} color={color}/>
}

const Button: React.FC<ButtonProps> = (props) => {
  const { style, textStyle, icon, children, loading, variant, ...otherProps } =
    props

  const text = props.text || null
  const msg = props.msg || null

  let variants = props.variant
  const isSmall = variants?.includes('small')

  let color = icon?.color || Theme.colors.grad1

  if (props.icon && (props.text || props.msg)) variants = `iconText ${variants}`
  if (props.icon) variants = `icon ${variants}`
  if (props.loading) variants = `loading ${variants}`
  if (props.disabled) variants = `${variants} disabled`
  if (props.disabled || variants?.includes('white')) color = 'white'

  const styles = getStyles(variants, ButtonStyles)
  let textIconStyle = {}
  if (icon?.side) {
    textIconStyle =
      icon?.side === 'left'
        ? { marginRight: Theme.spacing(2) }
        : { marginLeft: Theme.spacing(2) }
  }

  const content =
    text || msg
      ? [
        <Text
          key={`button-${props.variant}-${text}`}
          style={[styles.text, textStyle, textIconStyle]}
          text={text}
          variant={[color]}
          msg={msg}
        />,
      ]
      : []
  if (icon) {
    const IconComponent = <ButtonIcon IconProp={icon} isSmall={isSmall}/>
    icon.side === 'left'
      ? content.push(IconComponent)
      : content.unshift(IconComponent)
  }
  return (
    <Touchable
      style={[styles.wrapper, style]}
      {...otherProps}
      disabled={props.disabled || loading}
      variant={variants}
    >
      {content}
      {/* {loading && <LoadingAnimation/>} */}
    </Touchable>
  )
}

export default Button
