import { React, View, Text } from 'lib'
import { Theme } from 'app'
import PuffLoader from 'react-spinners/PuffLoader'
import { motion } from 'types/customMotion'

function Spinner(props: { visible: boolean }): React.ReactElement {
  const visible = props.visible ?? true
  return (
    <motion.View
      style={styles.wrapper}
      variants={expandingSectionVariants}
      animate={visible ? 'show' : 'init'}
      transition={{ bounce: 0.3 }}
    >
      <PuffLoader
        color={Theme.colors.grad1}
        loading={true}
        // cssOverride={override}
        size={50}
        aria-label='Loading Spinner'
        data-testid='loader'
      />
    </motion.View>
  )
}

const styles = {
  wrapper: {
    ...Theme.center,
    ...Theme.flex,
  },
}

const expandingSectionVariants = {
  init: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    duration: 450,
  },
}

export default Spinner
