import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { View, React, Text, Touchable, Fragment, Avatar, useRedux, useState, useEffect, navigate, Image } from 'lib'
import { CardHeader, RouteDetails, RouteInfo, ValueBox } from 'components'
import { Theme } from 'app'
import { CheckedShieldIcon, CheckIcon } from 'assets/icons'
import { Separator } from './Separator'
import { motion } from 'types/customMotion'
import { LiveTracking, Map } from 'actions'
import { RouteEvent } from 'types/ApiTypes'
import { CARD_TRIGGER_HEIGHT } from './CardHeader'
import navigationIcon from 'assets/navigation.png'
import { EmergencyIcon, PhoneCallIconSVG, ShareIcon, InfoIcon } from 'assets/icons'

const ProfileSection: React.FC = () => {
  const { profile } = useRedux('LiveTracking')
  return (
    <View variant='row justifyCenter alignCenter'>
      <Avatar
        variant='small'
        style={styles.avatar}
        profile={profile}
      />
      <View>
        <Text variant={`h4`} text={profile?.first_name}/>
        <Text variant={`p2`} text={`Journey History`}/>
      </View>
    </View>
  )
}

type TriggerComponentType = { Icon: React.ReactNode | JSX.Element, title: string, date: string, isSOS?: boolean, selected?: boolean, onPress?: () => void }
const Trigger: React.FC<TriggerComponentType> = ({ Icon, title, date, isSOS, selected, onPress }) => {
  const time = utcToZonedTime(date, 'utc')
  const formattedTime = format(new Date(time), 'hh:mm aaaa')
  const iconColor = Theme.colors[isSOS ? 'redStatus' : 'greyscale2']

  return (
    <Touchable onPress={() => onPress?.()} variant={'flex alignCenter justifySpaceBetween paddingVertical:2'}>
      <View variant={'flex alignCenter'}>
        {typeof Icon === 'function' ? <Icon size={24} color={iconColor}/> : Icon }
        <Text style={styles.triggerName} variant={`p2 ${isSOS ? 'alert' : 'greyscale2'} bold`} text={title}/>
      </View>
      <ValueBox value={formattedTime}/>
    </Touchable>
  )
}

const TriggersHistory: React.FC<{ visible?: boolean }> = ({ visible }) => {
  const { route, routeEvents } = useRedux('LiveTracking')
  const { triggerDetails } = useRedux('Map')

  const isSmall = Theme.hooks.down('small')
  const overflowMobile = !visible ? 'hidden' : 'auto'

  function triggerPress(data: RouteEvent) {
    Map.triggerPress(data, isSmall)
  }

  const includesWC = routeEvents.some((event) => event.events.routeStatus === 'WC')

  const getRouteEndMessage = () => {
    let message = ''

    if (includesWC && route?.status === 'DN') {
      message = 'Journey completed'
    } else if (!includesWC && route?.status === 'DN') {
      message = 'User ended journey'
    } else {
      message = 'User confirmed safety'
    }

    return message
  }

  return (
    <View variant={'fullWidth'} style={{ overflow: isSmall ? overflowMobile : 'auto', ...styles.historyWrapper }}>
      {route?.created_datetime && (
        <>
          <Separator spacing={2} topOnly/>
          <Trigger
            Icon={<CheckedShieldIcon size={24} color={Theme.colors.greyscale2}/>}
            title={'Started Journey'}
            date={route?.created_datetime}
          />
        </>
      )}

      {routeEvents.map((trigger, key) => {
        const { trigger: alert, event } = LiveTracking.useTrigger(trigger)

        const isSos = event === 'SO'
        const isSelected = triggerDetails?.visible && triggerDetails?.data?.created_datetime === trigger.created_datetime

        const lastIndex = routeEvents.length - 1

        if (alert) {
          return (
            <Fragment key={key}>
              <Separator spacing={0}/>
              <Trigger
                isSOS={isSos}
                date={trigger.created_datetime}
                Icon={alert.icon}
                title={alert.title}
                selected={isSelected}
                onPress={() => triggerPress(trigger)}
              />
            </Fragment>
          )
        }

        if (lastIndex === key && route?.active === false) {
          return (
            <>
              <Separator spacing={0}/>
              <Trigger
                Icon={CheckIcon}
                selected={false}
                onPress={() => null}
                date={route?.ended_datetime}
                title={getRouteEndMessage()}
              />
            </>
          )
        }

        return null
      })}
    </View>
  )
}

const RouteDetailsCard: React.FC = () => {
  const [expanded, setExpanded] = useState(true)
  const { triggerDetails } = useRedux('Map')

  const { trigger } = LiveTracking.useTrigger()

  const expandAnimation = expanded ? 'show' : 'minimized'
  const animation = triggerDetails?.visible ? expandAnimation : 'hide'

  let description = trigger?.description

  if (triggerDetails?.data?.currentLocation) {
    description = LiveTracking.defaultData.triggers.currentLocation.description
  }

  useEffect(() => {
    if (triggerDetails && !triggerDetails.data?.viewerLocation && (!expanded && triggerDetails.visible)) {
      setExpanded(triggerDetails.visible)
      Map.setHasExpandedTrigger(triggerDetails.visible)
    }
  }, [triggerDetails])

  return (
    <motion.View
      variants={cardPositionMobile}
      animate={animation}
      transition={{ bounce: 0.3 }}
      style={styles.wrapperMobile}
    >
      <CardHeader visible={expanded} setVisible={setExpanded} isTrigger noRadius={triggerDetails?.visible}/>
      <View variant={'paddingHorizontal:3 paddingVertical:2 white flex column'} style={styles.innerWrapper}>
        {description && <Text variant={'p2 marginBottom:2'} text={description}/>}
        <RouteInfo data={triggerDetails?.data}/>
      </View>
    </motion.View>
  )
}

const CallToActionButton: React.FC<{ button: any }> = ({ button }) => {
  return (
    <View style={styles.callToActionButton}>
      <Touchable
        onPress={button.onPress}
        style={{ ...styles.iconWrapper, backgroundColor: button.title === 'Emergency' ? Theme.colors.redStatus : Theme.colors.greyscale6 }}
      >
        {button.icon}
      </Touchable>
    </View>
  )
}

export const JourneyHistoryCard: React.FC = () => {
  const [visible, setVisible] = useState(false)
  const { route, profile, currentLocation } = useRedux('LiveTracking')
  const { triggerDetails } = useRedux('Map')

  const isSmall = Theme.hooks.down('small')

  const iconSize = 24

  const buttons = [
    {
      title: 'Call',
      icon: <PhoneCallIconSVG color={Theme.colors.greyscale1} size={iconSize}/>,
      onPress: () => navigate(`tel:${profile.phone}`),
    },
    {
      title: 'Directions',
      icon: <Image source={navigationIcon} style={{ objectFit: 'contain', width: iconSize, height: iconSize }}/>,
      onPress: () => window.open(`https://maps.google.com/?q=${currentLocation.lat},${currentLocation.lng}`, '_blank'),
    },
    {
      title: 'Emergency',
      icon: <EmergencyIcon color={Theme.colors.redStatus} size={iconSize}/>,
      onPress: () => navigate('tel:112'),
    },
    {
      title: 'Share',
      icon: <ShareIcon color={Theme.colors.greyscale1} size={iconSize}/>,
      onPress: () => {
        if (typeof window !== 'undefined') {
          const shareData = {
            title: 'Becon',
            text: 'Live Tracking',
            url: window?.location?.href,
          }

          if (window?.navigator?.share) {
            window.navigator.share(shareData)
          }
        }
      },
    },
    {
      title: 'Guidance',
      icon: <InfoIcon color={Theme.colors.greyscale1} size={iconSize}/>,
      onPress: () => window.open(`/guidance`, '_blank'),
    },
  ]

  const animate = isSmall
    ? !triggerDetails?.visible ? visible ? 'expanded' : 'minimized' : 'hide'
    : !triggerDetails?.visible ? visible ? 'show' : 'init' : 'hide'

  return (
    <View>
      {isSmall && <RouteDetailsCard/>}

      <motion.View
        variants={isSmall && cardPositionMobile}
        animate={animate}
        transition={{ bounce: 0.3 }}
        style={isSmall ? styles.wrapperMobile : styles.wrapper}
      >
        <CardHeader visible={visible} setVisible={setVisible} noRadius/>
        <View variant={'padding:3 flex column white'} style={styles.innerWrapper}>
          <View variant={`${!route?.created_datetime && 'marginBottom:2'}`}>
            <ProfileSection/>
          </View>
          {(!isSmall && route?.mode === 'ST') && (
            <>
              <Separator spacing={2}/>
              <RouteDetails/>
            </>
          )}
          <TriggersHistory visible={visible}/>
          <View style={styles.wrapperButtons}>
            {buttons.map((button, key) => (
              <CallToActionButton key={key} button={button}/>
            ))}
          </View>
        </View>
      </motion.View>
    </View>
  )
}
const cardPositionMobile = {
  init: {
    duration: 1500,
    height: '35svh',
    transform: 'translateY(0px)',
  },
  show: {
    duration: 2500,
    height: '100svh',
    transform: 'translateY(0px)',
  },
  hide: {
    duration: 300,
    height: '0',
    transform: 'translateY(0px)',
  },
  expanded: {
    duration: 2500,
    height: '100svh',
    transform: 'translateY(0px)',
  },
  minimized: {
    duration: 300,
    height: CARD_TRIGGER_HEIGHT,
    transform: 'translateY(0px)',
  },
}

export const HISTORY_CARD_MAX_WIDTH = 380

const styles = {
  avatar: {
    border: `3px solid ${Theme.colors.star}`,
    marginRight: Theme.spacing(2),
  },

  triggerName: {
    marginLeft: Theme.spacing(1.5),
  },
  selectedTrigger: {
    transform: 'translateX(35px)',
    transition: 'all .2s ease-in-out',
  },
  notSelectedTrigger: {
    transform: 'translateX(0px)',
    transition: 'all .2s ease-in-out',
  },
  historyWrapper: {
    maxHeight: '72svh',

    '::-webkit-scrollbar': {
      width: 0,
    },

    [Theme.media.down('small')]: {
      maxHeight: 'none',
    },
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxHeight: `calc(100svh - ${Theme.spacing(4)}px)`,
    maxWidth: HISTORY_CARD_MAX_WIDTH,
    marginBottom: Theme.spacing(2),
    backgroundColor: 'transparent',
    borderRadius: Theme.values.borderRadiusMedium,
    overflow: 'hidden',
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)',
    pointerEvents: 'auto',
  },
  wrapperMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    pointerEvents: 'auto',
  },
  wrapperButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    [Theme.media.up('small')]: {
      gap: Theme.spacing(0),
    },
  },
  callToActionButton: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: Theme.spacing(0.5),
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: Theme.spacing(2),
    borderRadius: Theme.values.borderRadiusMedium,
    [Theme.media.down('mid')]: {
      padding: Theme.spacing(1.5),
    },
    [Theme.media.down('small')]: {
      padding: Theme.spacing(1.5),
    },
  },
  innerWrapper: {
    width: '100%',
    height: '100%',
    alignItems: 'flex-start',
  },
}
