import { React } from 'lib'
import destinationMarker from 'assets/map_pin_v2.png'
import { Marker } from '@react-google-maps/api'
import { MarkerProps } from '.'
import { MARKER_SIZE } from './utils'
import { Theme } from 'app'

export const DestinationMarker: React.FC<MarkerProps> = ({ lat, lng }) => {
  const isMobile = Theme.hooks.down('small')

  const scaledSize = {
    height: MARKER_SIZE[isMobile ? 'destinationMobile' : 'destination'].height,
    width: MARKER_SIZE[isMobile ? 'destinationMobile' : 'destination'].width,
    equals: null,
  }

  return (
    <Marker
      position={{ lat, lng }}
      icon={{
        url: destinationMarker,
        scaledSize,
      }}
      visible
    />
  )
}
