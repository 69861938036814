import { View, React, Text, useRedux } from 'lib'
import { Theme } from 'app'
import { MapPinIcon, OriginCircle } from 'assets/icons'
import { Separator } from './Separator'
import { HISTORY_CARD_MAX_WIDTH } from './JourneyHistoryCard'

const iconSize = 18

export const RouteDetails: React.FC = () => {
  const { route } = useRedux('LiveTracking')

  const isMobile = Theme.hooks.down('small')

  const inputStyle = isMobile ? styles.inputMobile : styles.input

  return (
    <View style={styles.locationWrapper}>
      <View variant={'flex column'}>
        <View variant={'flex alignCenter'}>
          <OriginCircle size={iconSize} color={Theme.colors.light}/>
          <Text
            variant={'p2 light noWrap marginLeft:1'}
            style={inputStyle}
            text={`${route?.origin?.coords?.formatted_address ?? 'User location'}`}
          />
        </View>
        <View variant={'flex alignCenter'}>
          <View style={styles.dotWrapper}>
            {!isMobile && (
              <>
                <View style={styles.dot}></View>
                <View style={styles.dot}></View>
                <View style={styles.dot}></View>
              </>
            )}
          </View>
          <Separator spacing={isMobile ? 1 : 2}/>
        </View>
        <View variant={'flex alignCenter fullWidth'}>
          <MapPinIcon size={iconSize} color={Theme.colors.light}/>
          <Text
            variant={'p2 light noWrap marginLeft:1'}
            style={inputStyle}
            text={route?.destination?.formatted_address || route?.destination?.name}
          />
        </View>
      </View>
    </View>
  )
}


const styles = {
  dotWrapper: {
    minWidth: iconSize, // icons width
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: Theme.spacing(1),
    marginRight: Theme.spacing(1),
  },
  dot: {
    width: 4,
    height: 4,
    borderRadius: '50%',
    backgroundColor: `${Theme.colors.veryLight}`,
  },
  input: {
    [Theme.media.down('mid')]: {
      maxWidth: `calc(50vw - ${iconSize}px - ${Theme.spacing(7)}px)`,
    },

    [Theme.media.up('mid')]: {
      maxWidth: `calc(${HISTORY_CARD_MAX_WIDTH}px - ${iconSize}px - ${Theme.spacing(6)}px)`,
    },
  },

  inputMobile: {
    maxWidth: `calc(90vw - ${iconSize}px - ${Theme.spacing(6)}px)`,
  },

  locationWrapper: {
    width: '100%',
    display: 'flex',
  },
}
