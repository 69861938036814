import { Settings } from 'app'
import { Analytics } from 'lib'

type Stores = 'appStore' | 'playStore'

export const handleStoreLink = (store: Stores) => (store === 'appStore' ? Settings.APP_STORE_LINK : Settings.GOOGLE_PLAY_LINK)

export const LeadEvent = (store: Stores) => {
  const platform = store === 'appStore' ? 'IOS' : 'Android'
  if (typeof window !== 'undefined') {
    if (window.fbq != null) {
      window.fbq('track', 'Lead', { value: platform })
      window.fbq('trackCustom', `Lead - ${platform}`)
      window.open(handleStoreLink(store), '_blank', 'noopener,noreferrer')
    } else {
      window.open(handleStoreLink(store), '_blank', 'noopener,noreferrer')
    }
  }
}

export default {
  LeadEvent,
}
