import { filter, isEmpty } from "ramda"
import { React, equals, View } from "lib"
import faker from "faker"
import { colorHashMap } from "app"

function matchLetterToColor(initial: string) {
  if (!initial || colorHashMap[initial]) return "#999999"

  return colorHashMap[initial.toLowerCase()]
}

function waitFor(ms: number) {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
}

function csvJSON(csvString: string) {
  const lines = filter((n: string) => !isEmpty(n), csvString.split("\n"))
  const result = []

  const headers = lines[0].split(",")

  for (let i = 1; i < lines.length; i++) {
    const obj = {}
    const currentline = lines[i].split(",")

    for (let j = 0; j < headers.length; j++) {
      if (currentline[j]) {
        const key = headers[j].trim()
        obj[key] = currentline[j]
      }
    }
    result.push(obj)
  }

  return result
}

function arePropsEqual<T extends unknown>(
  prevProps: T,
  nextProps: T,
  checkProps: Record<keyof T, any>,
  debugMode = false
) {
  deb.yellow({ prevProps, nextProps, checkProps })
  for (const c in checkProps) {
    const item = checkProps[c]
    const nextItem = nextProps[item]
    const prevItem = prevProps[item]
    if (nextItem?.receivedAt) delete nextItem.receivedAt
    if (prevItem?.receivedAt) delete prevItem.receivedAt
    const res = equals(nextItem, prevItem)
    if (!res) {
      if (debugMode)
        deb.yellow("Props not equal", {
          item,
          nextItem,
          prevItem,
          prevProps,
          nextProps,
          checkProps,
        })
      return false
    }
  }
  return true
}

function stressTestFillBoxes(number = 5000, size = 10) {
  const testBoxes = []
  for (let i = 0; i < number; i++) {
    testBoxes.push(
      <View
        style={{
          height: size,
          width: size,
          backgroundColor: faker.commerce.color(),
        }}
      ></View>
    )
  }
  return testBoxes
}

export default {
  matchLetterToColor,
  waitFor,
  arePropsEqual,
  stressTestFillBoxes,
  csvJSON,
}
