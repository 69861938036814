import { Theme } from 'app'
import { PremiumLogo } from 'assets/PremiumLogo'
import { React, View, Text, useTheme } from 'lib'

export const Banner: React.FC = () => {
  const { isDark } = useTheme()
  return (
    <View style={[styles.bannerWrapper, isDark ? styles.lightBanner : styles.darkBanner]} variant={'row alignCenter padding:2'}>
      <View variant={'marginRight:2'}>
        <Text variant={`h6 marginBottom:1 ${isDark ? 'greyscale1' : 'white'}`} text={`Becon+ Is Coming Soon!`}/>
        <Text variant={`p4 marginBottom:1 ${isDark ? 'greyscale2' : 'greyscale5'}`} style={styles.textStyle} text={`Customise saftey settings, share live\njourneys, protect activities & more.`}/>
        <Text variant={`p4 bold ${isDark ? 'greyscale2' : 'greyscale5'}`} style={styles.textStyle} text={`Launching January 2023`}/>
      </View>

      <PremiumLogo size={42}/>
    </View>
  )
}

const styles = {
  bannerWrapper: {
    borderRadius: Theme.spacing(2),
    width: 'fit-content',
  },
  darkBanner: {
    background: Theme.colors.gradientBannerDark,
  },
  lightBanner: {
    background: Theme.colors.gradientBannerLight,
  },
  textStyle: {
    whiteSpace: 'pre-line',
  },
}
