import { View, Text, CenterWrapper, React, Link, Touchable } from 'lib'
import { Settings } from 'app'
import moment from 'moment'
import { Logo } from 'components'
import { FaFacebookSquare, FaLinkedin, FaInstagramSquare } from 'react-icons/fa'

import styles from './styles'

const linksA = [
  { text: 'HOME', to: '/' },
  { text: `ABOUT`, to: '/about' },
  { text: 'NEWS', to: '/news' },
]

const linksB = [
  { text: 'GUIDANCE', to: '/guidance' },
  { text: `CONTACT US`, to: '/contact' },
  { text: 'FAQ', to: '/faq' },
]

// const linksC = [
//   { text: `CONTACT US`, to: '/contact' },
// ]

function renderLinkCol(arr) {
  const links = arr.map(({ to, text }, index) => {
    const onPress = to.startsWith('/#') ? () => Tools.scrollTo(to.substring(1)) : null
    return <Link key={index} style={[styles.footerNavText, styles.link]} to={to} text={text} onPress={onPress}/>
  })
  return (
    <View style={styles.linkCol}>
      {links}
    </View>
  )
}

function Menu() {
  return (
    <View style={styles.menuWrapper}>
      {/* <Logo style={styles.logoImage}/> */}
      <View style={styles.footerLinkWrapper}>
        {renderLinkCol(linksA)}
        {renderLinkCol(linksB)}
        {/* {renderLinkCol(linksC)} */}
      </View>
    </View>
  )
}

function Disclaimers() {
  return (
    <View style={styles.disclaimersWrapper}>
      {/* <Image src={'assets/codeleap_logo_white.png'} alt='CodeLeap' style={styles.logoImage}/> */}
      <Text style={styles.regDetailsText}>
        Becon Tech Ltd. is registered in England&nbsp;&&nbsp;Wales, company&nbsp;no.&nbsp;13301823.
      </Text>
      <Text variant={'inline'} style={styles.regDetailsText}>
        This website uses&thinsp;<Link openNewTab style={styles.link} to='/website-licenses'>third-party assets and software</Link>.
        By using Becon Sites you agree to the&thinsp;<Link openNewTab style={styles.link} to='/legal'>Terms Of Use & Privacy Policy</Link>.
      </Text>
      <Text variant={'inline'} style={styles.regDetailsText}>
        Becon was developed in collaboration with&nbsp;<Link openNewTab style={styles.link} to='https://codeleap.co.uk'>CodeLeap</Link>&nbsp;🚀
      </Text>
    </View>
  )
}

function Contact() {
  const year = moment().format('YYYY')
  return (
    <View style={styles.contactWrapper}>
      <Link text={Settings.CONTACT_EMAIL} href={`mailto:${Settings.CONTACT_EMAIL}`} style={[styles.footerNavText, styles.link]}/>
      {/* <Link text={Settings.CONTACT_PHONE} href={`tel:${Settings.CONTACT_PHONE}`} style={[styles.footerNavText, styles.link]}/> */}
      <Text style={styles.footerNavText}>Made with ♥️ in London, UK</Text>
      <Text style={styles.footerNavText} text={`©${year} Becon Tech Ltd.`}/>
    </View>
  )
}

function SocialMedia() {
  return (
    <View style={styles.socialMediaWrapper}>
      <Touchable style={styles.socialMediaIconWrapper} to={Settings.FACEBOOK_URL}>
        <FaFacebookSquare size={28} color={'white'}/>
      </Touchable>
      <Touchable style={styles.socialMediaIconWrapper} to={Settings.INSTAGRAM_URL}>
        <FaInstagramSquare size={28} color={'white'}/>
      </Touchable>
      <Touchable style={styles.socialMediaIconWrapper} to={Settings.LINKEDIN_URL}>
        <FaLinkedin size={28} color={'white'}/>
      </Touchable>
    </View>
  )
}

export default function Footer() {
  return (
    <View style={styles.wrapper}>
      <CenterWrapper>
        <View style={styles.innerWrapper}>
          {/* <Image src={'../.png'} alt='CodeLeap' style={styles.logoImage}/> */}
          <Link to={'/'}>
            <Logo
              style={styles.logoImage}
              onClick={() => window.scroll({
                top: 0,
                behavior: 'smooth',
              })
              }
            />
          </Link>
          <View up={'mid'}>
            <View style={styles.innerWrapperRow}>
              <Menu/>
              <Contact/>
            </View>
            <View style={styles.separator}></View>
            <View style={styles.bottomRow}>
              <Disclaimers/>
              <SocialMedia/>
            </View>
          </View>
          <View down={'mid'} style={styles.innerWrapperColumn}>
            <Menu/>
            <View style={styles.separator}></View>
            <Contact/>
            <SocialMedia/>
            <Disclaimers/>
          </View>
        </View>
      </CenterWrapper>
    </View>
  )
}
