import { GoogleMap as GoogleMapsReact, useJsApiLoader } from '@react-google-maps/api'
import { onMount, React, useRedux, useTheme, View, useState } from 'lib'
import { Settings, MapTheme, Theme } from 'app'
import { Map as MapActions } from 'actions'
import { BeconPolyline, RoutePolyline, DestinationMarker, OriginMarker, UserMarker, AlertMarker, RouteDetailsBox } from 'components'
import { InactivePolyline } from './Polylines'

export const Map: React.FC = (props) => {
  const [viewerPosition, setViewerPosition] = useState(null)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: Settings.GOOGLE_MAPS_API_KEY,
  })
  const { route, currentLocation, userRoute, routeEvents, profile } = useRedux('LiveTracking')
  const { triggerDetails } = useRedux('Map')
  const { isLight } = useTheme()
  const isSmall = Theme.hooks.down('small')
  const initialZoom = MapActions.MAP_ZOOM.value[isSmall ? 'mobile' : 'default']

  const viewerPositionId = typeof window !== 'undefined' ? navigator?.geolocation?.watchPosition(position => {
    setViewerPosition(position)
  }) : null

  const onLoad = (map: google.maps.Map) => {
    MapActions.setMap(map)
    MapActions.fitToUserLocation({ isSmall, map })
    map.moveCamera({ zoom: MapActions.MAP_ZOOM.value[isSmall ? 'mobile' : 'default'] }) // setZoom does not work during loanding
  }

  const onUnmount = React.useCallback(() => {
    MapActions.setMap(null)
    if (typeof window !== 'undefined' && viewerPositionId) {
      navigator?.geolocation?.clearWatch(viewerPositionId)
    }
  }, [])

  function onMapClick() {
    MapActions.clear(triggerDetails.data)
  }

  onMount(() => {
    if (isSmall && routeEvents.length >= 1) {
      const latestAlert = routeEvents[routeEvents.length - 1]

      if (latestAlert) MapActions.setTriggerDetails(false, latestAlert)
    }
  })

  return (
    <View variant={'full'} style={styles.map}>
      {isLoaded ? (
        <GoogleMapsReact
          mapContainerStyle={styles.mapContainer}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onDragStart={onMapClick}
          onClick={onMapClick}
          options={{
            styles: isLight ? MapTheme.light : MapTheme.dark,
            disableDoubleClickZoom: !isSmall,
            zoomControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            clickableIcons: false,
            maxZoom: MapActions.MAP_ZOOM.max,
            gestureHandling: 'greedy',
          }}
        >
          {route.mode === 'ST' && (
            <>
              <DestinationMarker lat={route?.destination?.coords?.lat} lng={route?.destination?.coords?.lng}/>
              <OriginMarker lat={route?.origin?.coords?.lat} lng={route?.origin?.coords?.lng}/>
            </>
          )}

          {viewerPosition && (
            <UserMarker self profile={profile} data={routeEvents[routeEvents.length-1]} lat={viewerPosition?.coords?.latitude} lng={viewerPosition?.coords?.longitude}/>
          )}

          <UserMarker profile={profile} data={routeEvents[routeEvents.length-1]} lat={currentLocation?.lat} lng={currentLocation?.lng}/>
          {routeEvents?.map((data, key) => <AlertMarker key={key} data={data}/>)}
          <RouteDetailsBox/>

          {route?.mode === 'ST' && <RoutePolyline mapMarkers={route?.coords}/>}

          {userRoute.map((data, key) => {
            return data?.disconnected || data?.paused ? <InactivePolyline key={key} mapMarkers={data.coords}/> : <BeconPolyline key={key} mapMarkers={data.coords}/>
          })}

          {props?.children}
        </GoogleMapsReact>
      ) : null}
    </View>
  )
}

const styles = {
  mapContainer: {
    width: '100vw',
    height: '100svh',
  },
  map: {
    width: '100%',
    height: '100%',

    '.gm-ui-hover-effect': {
      display: 'none !important',
    },
    '.gm-style-iw': {
      padding: `0px !important`,
      borderRadius: '100%',
    },
    '.gm-style-iw-d': {
      overflow: 'hidden !important',
      padding: `0px !important`,
    },
    '.gm-style .gm-style-iw-tc::after': {
      background: Theme.colors.white,
      height: 15,
      left: 4.5,
      top: -3,
      width: 16,
      filter: 'drop-shadow(0px 7px 7px rgba(0,0,0,0.2))',
    },
    '.infoBox': {
      marginTop: -130,
      marginLeft: Theme.spacing(5),
      boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)',
      borderRadius: Theme.values.borderRadiusMedium,
      zIndex: 999999,
      maxWidth: 400,
      width: '100%',

      img: {
        display: 'none !important',
      },
    },

    'div[aria-label="plannedRoute"] + .gm-style-iw-tc': {
      transform: 'translateX(125%)',
      filter: 'none',
    },
    'div[aria-label="plannedRoute"] + .gm-style-iw-tc::after': {
      background: Theme.colors.grad3,
      boxShadow: '1px 4px 6px rgba(0, 0, 0, 0.2)',
    },

    'div[aria-label="plannedRoute"]': {
      borderRadius: 50,
    },
  },
}
