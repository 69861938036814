import { React, View, Text, Touchable, StyleSheet } from 'lib'
import { Theme } from 'app'
import { StarIcon, ArrowRightIcon, ArrowLeftIcon } from 'assets/icons'
import Slider, { Settings, CustomArrowProps } from 'react-slick'
import '_app/stylesheets/css/slick.css'
import '_app/stylesheets/css/slick-theme.css'
// import { ReviewProps } from 'pages/_homepage/_usersReviews'

interface ArrowProps extends CustomArrowProps {
  icon: JSX.Element;
}

const CarouselArrow: React.FC<ArrowProps> = ({ icon, onClick }) => {
  return (
    <Touchable onPress={onClick}>
      {icon}
    </Touchable>
  )
}

const settings: Settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 20000,
  nextArrow: <CarouselArrow icon={<ArrowRightIcon size={30} color={Theme.colors.white}/>}/>,
  prevArrow: <CarouselArrow icon={<ArrowLeftIcon size={30} color={Theme.colors.white}/>}/>,
  responsive: [
    {
      breakpoint: Theme.breakpoints.largeish,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: Theme.breakpoints.mid,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
      },
    },
    {
      breakpoint: Theme.breakpoints.small,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
}

export const ReviewCard = ({ name, title, rating, content, color }) => {
  return (
    <View
      variant={'marginHorizontal:2'}
      style={[styles.cardInnerWrapper, color === 'black' ? { backgroundColor: Theme.colors.white } : { backgroundColor: 'rgba(255, 255, 255, 0.05)' }]}
    >
      <View>
        <Text variant={`h5 ${color}`} text={title}/>
        <View variant={'marginTop:1'}>
          {new Array(rating).fill(<StarIcon size={Theme.spacing(2)} color={Theme.colors.star}/>)}
        </View>
      </View>

      <View variant={'flex column justifySpaceBetween marginTop:2'}>

        <Text variant={`p4 ${color}`} style={styles.body} text={content}/>

        <View>
          <Text variant={`p3 ${color}`} text={`by`}/>
          <Text variant={`h6 ${color}`} text={name}/>
        </View>
      </View>

    </View>
  )
}

export const Carousel = ({ reviews, color }) => (
  <Slider {...settings}>
    {reviews.map((item, key) => (
      <ReviewCard
        key={key}
        color={color}
        {...item}
      />
    ))}
  </Slider>
)

const styles = StyleSheet.createComponentStyleSheet({
  cardInnerWrapper: {
    minHeight: 350,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: Theme.spacing(4),
    borderRadius: 16,
  },
  body: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box !important',
    WebkitLineClamp: 6,
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'normal',
  },
})
