import { store } from '../reducers'
import { Cookies, url as parseUrl, useRedux } from 'lib'
import Fetch from './Fetch'
import moment from 'moment'
import UAParser from 'ua-parser-js'
import isbot from 'isbot'

//
// GETTERS AND SETTERS
//

type DebugData = { city: string; country_name: string; org?: string }
export function init() {
  log('init session')
}

export function acceptCookies() {
  store.dispatch({ type: 'ACCEPT_COOKIES' })
  Cookies.set('Session.cookiesAccepted', 'true')
}

function getVisits() {
  const visits = Cookies.get('Session.visits')
  Cookies.set('Session.visits', `${parseInt(visits || '1') + 1}`)
  return visits
}

function getLastSeen() {
  const lastSeenCookie = Cookies.get('Session.lastSeen')
  Cookies.set('Session.lastSeen', new Date())
  const lastSeenMoment = moment(lastSeenCookie)
  const now = moment()
  const timeElapsed = moment
    .duration(now.diff(lastSeenMoment, 'minutes'), 'minutes')
    .humanize()
  const lastSeenFormatted = lastSeenMoment.format(
    'ddd, MMM Do YYYY HH:mm [GMT]ZZ',
  )
  const lastSeenStr = `${lastSeenFormatted}, ${timeElapsed} ago`
  const lastSeen = lastSeenStr || ''
  return lastSeen
}

function getFirstSeen() {
  const firstSeenCookie = Cookies.get('Session.firstSeen')
  const firstSeenMoment = moment(firstSeenCookie)
  const now = moment()
  const timeElapsed = moment
    .duration(now.diff(firstSeenMoment, 'minutes'), 'minutes')
    .humanize()
  const firstSeenFormatted = firstSeenMoment.format(
    'ddd, MMM Do YYYY HH:mm [GMT]ZZ',
  )
  const firstSeenStr = `${firstSeenFormatted}, ${timeElapsed} ago`
  const firstSeen = firstSeenStr || ''
  return firstSeen
}

function getFirstUrl() {
  const firstUrl = Cookies.get('Session.firstUrl') || ''
  return firstUrl
}

function getCurrentUrl() {
  const raw = window && window.location.href
  return raw
}

function getCurrentParsedUrl() {
  const raw = getCurrentUrl()
  const parsed = parseUrl(raw)
  const str = `${parsed.origin}${parsed.pathname}`
  return str
}

function getAdword() {
  const url = parseUrl(window.location.href, true)
  return url.query
}

export async function getSessionData(userAnalyticsId = null) {
  if (!userAnalyticsId) {
    userAnalyticsId = store.getState().Session.userAnalyticsId
  }
  const [location, debugData] = await getIPInfo()
  const referrer = getReferrer()
  const currentUrl = getCurrentUrl()
  const currentParsedUrl = getCurrentParsedUrl()
  const adWord = getAdword()
  const lastSeen = getLastSeen()
  const firstSeen = getFirstSeen()
  const firstUrl = getFirstUrl()
  const visits = getVisits()
  const isBot = getIsBot()
  const [userAgentRaw, userAgentParsed] = getUserAgent()
  const sessionData = {
    userAnalyticsId,
    referrer,
    currentUrl,
    currentParsedUrl,
    adWord,
    firstSeen,
    firstUrl,
    lastSeen,
    visits,
    isBot,
    debugData: {},
    org: {},
    userAgentRaw,
    userAgentParsed,
    location,
  }

  const noEmptyValues = Object.fromEntries(
    Object.entries(sessionData).filter(([_, v]) => Boolean(v)),
  )

  return noEmptyValues
}

//
// FETCH AND BROWSER STUFF
//

function getReferrer() {
  if (window) {
    if (document && document.referrer) {
      return document.referrer
    } else {
      return 'direct'
    }
  } else {
    return null
  }
}

function getUserAgent() {
  try {
    const uaString = window.navigator.userAgent
    const parsed = new UAParser(uaString).getResult()
    const parsedString = `${
      parsed?.browser?.name
    } ${parsed?.browser?.version?.split('.', 1)} on ${parsed?.os?.name} ${
      parsed?.os?.version
    }`
    // log({ uaString, parsedString, parsed: parsed })
    return [uaString, parsedString]
  } catch (err) {
    warn(err)
    return ['N/A', 'Not available']
  }
}

function getIsBot() {
  const isBot = isbot(window.navigator.userAgent)
  return isBot
}

function getIPInfo(onSuccess = () => null, onFailure = () => null) {
  return new Promise<[string, DebugData]>((resolve, reject) => {
    Fetch({
      url: 'https://ipapi.co/json/',
      method: 'GET',
      options: {
        json: true,
        full_url: true,
      },
      onSuccess: (data) => {
        const string = `${data.city}, ${data.country_name}`
        resolve([string, data])
        onSuccess()
      },
      onFailure: (err) => {
        warn(err)
        reject(err)
        onFailure()
      },
    })
  })
}

function getTheme() {
  const themeExists = localStorage.getItem('@Beacon.theme') as 'light' | 'dark'

  if (themeExists) {
    store.dispatch({ type: 'SET_THEME', data: { theme: themeExists } })

    return themeExists
  }
}

function setTheme(currentTheme: 'light' | 'dark') {
  const theme = currentTheme === 'light' ? 'dark' : 'light'
  localStorage.setItem('@Beacon.theme', theme)
  store.dispatch({ type: 'SET_THEME', data: { theme } })

  return theme
}

export default {
  init,
  getSessionData,
  acceptCookies,
  setTheme,
  getTheme,
}
