/* eslint-disable @typescript-eslint/ban-types */
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import { React, View, Text, Link } from 'lib'
import { Theme } from 'app'
import { ValueBox, Separator } from 'components'

import { SpeedometerIcon, ClockIcon, MapPinIcon, CompassIcon, AccuracyIcon, BatteryIcon } from 'assets/icons'
import { RouteEvent } from 'types/ApiTypes'
import { CSSInterpolation } from 'types/style'

type RouteInfoProps = {
  data: RouteEvent,
  styles?: React.CSSProperties & (CSSInterpolation | CSSInterpolation[] | Partial<React.CSSProperties>) | object
}

const RowComponent: React.FC<{ icon: JSX.Element, title: string, value: string | number, link?: string, isRed?: boolean }> = (data) => {
  return (
    <View variant={'flex alignCenter justifySpaceBetween'}>
      <View variant={'flex alignCenter'}>
        {data.icon}
        <Text variant={`p3 greyscale2 marginLeft:1 ${data?.isRed && 'alert'}`} text={data.title}/>
      </View>
      <Link to={data?.link} openNewTab>
        <ValueBox color={data?.isRed && Theme.colors.redStatus} value={data.value}/>
      </Link>
    </View>
  )
}

export const RouteInfo: React.FC<RouteInfoProps> = ({ data, styles: newStyles = {} }) => {

  const time = data?.created_datetime ? utcToZonedTime(data?.created_datetime, 'utc') : ''
  const formattedTime = time ? format(new Date(time), 'hh:mm aaaa') : ''

  const batteryLevel = data?.batteryLevel ? (data?.batteryLevel * 100)?.toFixed() : null
  const isLowBattery = !batteryLevel || batteryLevel <= 20

  return (
    <View style={{ ...styles.bodyWrapper, ...newStyles }}>
      {formattedTime ? <RowComponent icon={<ClockIcon size={24} color={Theme.colors.light}/>} title={'Timestamp'} value={formattedTime}/> : null}

      <Separator spacing={1}/>
      <RowComponent icon={<SpeedometerIcon size={24} color={Theme.colors.light}/>} title={'Speed'} value={`${data?.speed?.toFixed(1) || 0} km/h`}/>

      <Separator spacing={1}/>
      <RowComponent icon={<BatteryIcon size={24} color={Theme.colors[isLowBattery ? 'redStatus' : 'light']}/>} title={'Battery'} isRed={batteryLevel <= 20} value={batteryLevel ? `${batteryLevel}%` : 'N/A'}/>

      <Separator spacing={1}/>
      <RowComponent icon={<MapPinIcon size={24} color={Theme.colors.light}/>} title={'Coordinates'} value={`${data?.coords?.lat?.toFixed(3)}, ${data?.coords?.lng?.toFixed(3) || 0}`}/>

      <Separator spacing={1}/>
      <RowComponent icon={<MapPinIcon size={24} color={Theme.colors.light}/>} title={'W3W'} link={data?.w3w?.map} value={`//${data?.w3w?.words}`}/>

      <Separator spacing={1}/>
      <RowComponent icon={<AccuracyIcon size={24} color={Theme.colors.light}/>} title={'Accuracy'} value={(data?.accuracy?.toFixed() || 0) + 'm'}/>

      <Separator spacing={1}/>
      <RowComponent icon={<CompassIcon size={24} color={Theme.colors.light}/>} title={'Heading'} value={`${data?.heading || 0}°`}/>
    </View>
  )
}

const styles = {
  bodyWrapper: {
    width: '100%',
    backgroundColor: Theme.colors.white,
  },
}
