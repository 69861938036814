import Fetch from './Fetch'

const verifyToken = async (token: string, onSuccess?: () => void, onFailure?: () => void) => {
  await Fetch({
    url: `profiles/verify/?code=${token}`,
    method: 'GET',
    options: {
      json: false,
    },
    onSuccess: () => {
      onSuccess?.()
    },
    onFailure: () => {
      onFailure?.()
    },
  })
}

export default { verifyToken }
