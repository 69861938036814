/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Theme } from 'app'
import { React, View, Text, Button, Touchable, Fragment } from 'lib'
import { Separator } from './Separator'
import { motion } from 'types/customMotion'
import { CloseIcon } from 'assets/icons'
import { mapCaptions } from './MapCaptions'

type cardProps = {
  visible?: boolean;
  onDismiss: () => void
}

export const InformationCard: React.FC<cardProps> = ({ visible, onDismiss }) => {
  const isSmall = Theme.hooks.down('small')
  const radius = visible ? 0 : Theme.values.borderRadiusLarge
  const wrapperStyle = !isSmall ? styles.wrapper : { ...styles.smallWrapper, borderTopLeftRadius: radius, borderTopRightRadius: radius }
  const innerWrapperStyle = !isSmall ? styles.innerWrapper : styles.smallInnerWrapper
  return (
    <motion.View
      variants={isSmall ? cardPositionMobile : cardPosition}
      animate={visible ? 'show' : 'init'}
      transition={{ bounce: 0.3 }}
      style={wrapperStyle}
    >
      <View style={innerWrapperStyle}>
        <View variant={'flex alignCenter justifySpaceBetween marginBottom:2'}>
          <Text variant={`h4 bold`} text={`Information`}/>
          <Touchable onPress={onDismiss}>
            <CloseIcon
              size={24}
              color={Theme.colors.greyscale1}
            />
          </Touchable>
        </View>
        {mapCaptions.map(({ icon: Icon, ...trigger }, key) => (trigger?.show ? (
          <Fragment key={key}>
            {key !== 0 && <Separator spacing={1.5}/>}
            <View variant={'flex'}>
              {/*@ts-ignore*/}
              {trigger.isIcon ? <Icon size={24} color={Theme.colors.greyscale2}/> : Icon()}
              <View variant={'flex column'} style={styles.itemTextWrapper}>
                <Text variant={`p3 bold`} text={trigger.title}/>
                <Text variant={`p3`} text={trigger.description}/>
              </View>
            </View>
          </Fragment>
        ) : null))}
        <Button variant={'bgGrey dark marginTop:2 paddingVertical:2 fullWidth'} to={'/guidance'} openNewTab text='GUIDANCE'/>
      </View>
    </motion.View>
  )
}

const cardPosition = {
  init: {
    transform: 'translateY(100%)',
    opacity: 0,
    duration: 1500,
  },
  show: {
    opacity: 1,
    transform: 'translateY(0px)',
    duration: 2500,
  },
}

const cardPositionMobile = {
  init: {
    transform: 'translateY(750px)',
    opacity: 0,
    duration: 1500,
    height: 0,
  },
  show: {
    opacity: 1,
    transform: 'translateY(0px)',
    duration: 2500,
    height: '100%',
  },
}

const styles = {
  wrapper: {
    position: 'absolute',
    zIndex: 99999,
    bottom: 0,
    right: 0,
    maxWidth: 350,

    backgroundColor: Theme.colors.white,
    borderRadius: Theme.values.borderRadiusMedium,
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)',

    marginRight: Theme.spacing(2),
    marginBottom: Theme.spacing(2),
  },
  smallWrapper: {
    position: 'absolute',
    zIndex: 99999,
    bottom: 0,
    right: 0,
    left: 0,
    maxWidth: 'none',

    backgroundColor: Theme.colors.white,
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)',
  },
  innerWrapper: {
    ...Theme.paddingHorizontal(3),
    ...Theme.paddingVertical(2),
    maxHeight: `calc(100svh - ${Theme.spacing(4)}px)`,
    overflowY: 'auto',
    '::-webkit-scrollbar': {
      display: 'none',
    },
  },
  smallInnerWrapper: {
    ...Theme.paddingHorizontal(3),
    ...Theme.paddingVertical(2),
    maxHeight: '100svh',
    overflowY: 'auto',
    '::-webkit-scrollbar': {
      display: 'none',
    },
  },
  itemTextWrapper: {
    marginLeft: Theme.spacing(1.25),
    gap: Theme.spacing(0.5),
  },
}
