import FacebookEvents from 'actions/modules/FacebookEvents'
import { Settings, Theme } from 'app'
import { PlayStore, AppStore } from 'assets/StoreAssets'
import { React, Touchable, Text, StyleSheet } from 'lib'

export const PlayStoreButton: React.FC = () => (
  <Touchable onPress={() => FacebookEvents.LeadEvent('playStore')}>
    <a href={Settings.GOOGLE_PLAY_LINK} target='_blank' rel='noopener, noreferrer'>
      <PlayStore bgColor={Theme.colors.black}/>
      <Text style={styles.button}>Download Becon on Google Play</Text>
    </a>
  </Touchable>
)


export const AppStoreButton: React.FC = () => (
  <Touchable onPress={() => FacebookEvents.LeadEvent('appStore')}>
    <a href={Settings.APP_STORE_LINK} target='_blank' rel='noopener, noreferrer'>
      <AppStore bgColor={Theme.colors.black}/>
      <Text style={styles.button}>Download Becon on App Store</Text>
    </a>
  </Touchable>
)


const styles = StyleSheet.createComponentStyleSheet({
  button: {
    display: 'none',
  },
})
