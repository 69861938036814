const destinationSize = 42

export const MARKER_SIZE = {
  origin: 50,
  user: 62,
  destination: {
    width: destinationSize,
    height: destinationSize + 20,
  },
  destinationMobile: {
    width: destinationSize - 16,
    height: destinationSize,
  },
  alert: 12,
  selectedCoords: 12,
}
