import { View, React, StyleSheet } from "lib"
import { Theme } from "app"
import { CSSProperties } from "react"
type SectionWrapperProps = {
  style?: CSSProperties
  contentContainerStyle?: CSSProperties
}
const SectionWrapper: React.FC<SectionWrapperProps> = (props) => {
  const { style, contentContainerStyle, children } = props
  return (
    <View style={[styles.wrapper, style]}>
      <View style={[styles.innerWrapper, contentContainerStyle]}>
        {children}
      </View>
    </View>
  )
}

const styles = StyleSheet.createComponentStyleSheet({
  wrapper: {},
  innerWrapper: {
    ...Theme.paddingVertical(12),
    [Theme.media.down("mid")]: {
      ...Theme.paddingVertical(8),
    },
  },
})
export default SectionWrapper
