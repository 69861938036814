/* eslint no-console: "off" */
import { Settings } from 'app'
import callsites from 'callsites'
import { CSSProperties } from 'react'

/**
 * How to use the global logger things
 *
 * @global log() => log('key', value) // always appear in console as log
 * @global warn() => warn('key', value) // will always appear in console as warning
 * @global info() => info('key', value) // only appears if options is enabled
 * @global error() => error('string') // only appears if options is enabled
 */

const IGNORED_WARNINGS = [
  'Require cycle:',
  'Warning: Unsupported style property %s. Did you mean %s?%s @media screen',
]
if (Settings.WARN_INTERNATIONALIZATION == false) {
  IGNORED_WARNINGS.push('[React Intl]')
}
const logColors = ['green', 'blue', 'red', 'yellow', 'cyan', 'purple']
const LogColors: Record<LogTypes, string> = {
  error: '#f76a47',
  info: '#74b9ff',
  log: '#eee',
  warn: '#fdcb6e',
  perf: 'cyan',
}

const loggerFactory: LoggerFactory = (
  type: LogTypes,
  textColor?: CSSProperties['color'],
) => {
  const calls = callsites()

  const funcName = calls[calls.length - 1]?.getFunctionName
    ? calls[calls.length - 1].getFunctionName()
    : 'Untraceable'
  const caller = funcName && !funcName.includes('calle') ? funcName + '() ' : ''
  const nativeLogger = ['warn', 'error'].includes('type')
    ? console[type]
    : console.log
  return (key, value, color) => {

    if (!Settings.IS_DEVELOPMENT) return null

    const style = `color:  ${color || textColor || LogColors[type]}`

    const name = typeof key === 'string' && Boolean(value) ? `${key} : ` : ''
    const val = value || key
    if (typeof val === 'string') {
      nativeLogger(
        `%c [${type.toUpperCase()}] ` +
          name +
          (caller ? `\n at ${caller} ` : '') +
          val,
        style,
      )
    } else {
      nativeLogger(
        `%c [${type.toUpperCase()}] ` + name + (caller ? `\n ${caller}` : ''),
        style,
        val,
      )
    }
  }
}
export default loggerFactory

const baseDebugLoggers = Object.fromEntries(
  logColors.map((cl) => [cl, loggerFactory('log', cl)]),
)
const perfLogger = loggerFactory('perf', 'brown')
global.deb = {
  ...baseDebugLoggers,
  perf: (...args: Parameters<LoggerFn>) => {
    if (Settings.DEBUG_PERFORMANCE) {
      perfLogger(...args)
    }
  },
}

global.logger = Object.fromEntries(
  logColors.map((cl) => [cl, loggerFactory('log', cl)]),
)

global.log = loggerFactory('log')

global.warn = loggerFactory('warn')

global.info = loggerFactory('info')

global.error = loggerFactory('error')

// ignores some warnings to make shit less annoying
if (Settings.IS_DEVELOPMENT) {
  const oldConsoleWarn = console.warn
  const oldConsoleError = console.error
  const newConsole = (args, oldConsole) => {
    // const tmp = args.join(' ')
    // console.log('tmp', tmp)
    if (
      typeof args[0] === 'string' &&
      IGNORED_WARNINGS.some((ignoredWarning) => args.join(' ').includes(ignoredWarning),
      )
    ) {
      return
    }
    return oldConsole.apply(console, args)
  }
  console.warn = (...args) => newConsole(args, oldConsoleWarn)
  console.error = (...args) => newConsole(args, oldConsoleError)
}
