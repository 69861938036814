export const colorHashMap = {
  a: '#7CB9E8',
  b: '#3a9e77',
  c: '#A3C1AD',
  d: '#E1BD27',
  e: '#badc58',
  f: '#db5970',
  g: '#9b8ef1',
  h: '#ffe169',
  i: '#3ea9d1',
  j: '#8aa341',
  k: '#baf2f5',
  l: '#ffa02d',
  m: '#d46830',
  n: '#62ecaa',
  o: '#ffbe50',
  p: '#0078D7',
  q: '#8764B8',
  r: '#52dd64',
  s: '#7edce9',
  t: '#dadd5d',
  u: '#e9b55d',
  v: '#99d669',
  w: '#a3c83a',
  x: '#f28d67',
  y: '#ea82ec',
  z: '#ff8295',
}
