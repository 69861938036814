import { View, React, equals, StyleSheet } from 'lib'
import { Theme } from 'app'

function CenterWrapper(props) {
  const { id, style, contentContainerStyle, fullWidth, children } = props
  const isSocialBrowser = Theme.values.isSocialBrowser
  return (
    <View id={id} style={[styles.wrapper, isSocialBrowser && styles.wrapperSocialBrowser, fullWidth && styles.wrapperFullWidth, style]}>
      <View style={[styles.innerWrapper, fullWidth && styles.innerWrapperFullWidth, contentContainerStyle]}>
        {children}
      </View>
    </View>
  )
}

function areEqual(prevProps, nextProps) {
  const res = equals(prevProps, nextProps)
  return res
}

export default React.memo(CenterWrapper, areEqual)

const styles = StyleSheet.createComponentStyleSheet({
  wrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    width: '100%',
    [Theme.media.down('xxlarge')]: {
      paddingLeft: Theme.spacing(16),
      paddingRight: Theme.spacing(16),
    },
    [Theme.media.down('large')]: {
      paddingLeft: Theme.spacing(12),
      paddingRight: Theme.spacing(12),
    },
    [Theme.media.down('largeish')]: {
      paddingLeft: Theme.spacing(8),
      paddingRight: Theme.spacing(8),
    },
    [Theme.media.down('mid')]: {
      paddingLeft: Theme.spacing(4),
      paddingRight: Theme.spacing(4),
    },
    [Theme.media.down('small')]: {
      paddingLeft: Theme.spacing(2),
      paddingRight: Theme.spacing(2),
    },
  },
  wrapperFullWidth: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
    backgroundColor: 'red',
  },
  wrapperSocialBrowser: {
    paddingLeft: `${Theme.spacing(2)}px !important`,
    paddingRight: `${Theme.spacing(2)}px !important`,
  },
  innerWrapper: {
    flex: 1,
    display: 'flex',
    width: '100%',
    maxWidth: Theme.values.maxContentWidth,
  },
  innerWrapperFullWidth: {
    maxWidth: null,
  },
})
