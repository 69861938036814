import { Theme } from 'app'
import { React, Text, View } from 'lib'

// No idea on how to name this component
export const ValueBox: React.FC<{ value: string, color?: string }> = ({ value, color }) => {
  const beforeStyle = {
    backgroundColor: color ? '': Theme.colors.greyscale6,
    '&:before': {
      ...styles.bgWrapper,
      ...(color ? { backgroundColor: color, opacity: 0.15 } : {}),
    },
  }

  return (
    <View style={[styles.wrapper, beforeStyle]}>
      <Text variant={`p3 regular`} style={{ color: color || Theme.colors.greyscale10 }} text={value}/>
    </View>
  )
}

const styles = {
  wrapper: {
    width: 'fit-content',
    paddingTop: Theme.spacing(0.5),
    paddingBottom: Theme.spacing(0.5),
    paddingRight: Theme.spacing(2.25),
    paddingLeft: Theme.spacing(2.25),
    borderRadius: 18,
    position: 'relative',
    overflow: 'hidden',
  },

  bgWrapper: {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}
