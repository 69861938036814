const BASE_URLS = {
  PRODUCTION: `https://prod.folloapp.co/`,
  DEVELOPMENT: `https://api-follo.codeleap.co.uk/`,
  // LOCAL: `http://${hostname}:${debuggerPort}/api/`,
}

const IS_DEV_URL = typeof window !== 'undefined' && (window?.location?.href?.indexOf('beta') > -1 || window?.location?.href?.indexOf('localhost') > -1 || window?.location?.href?.indexOf('192.168') > -1)
const BASE_URL = IS_DEV_URL ? BASE_URLS.DEVELOPMENT : BASE_URLS.PRODUCTION

const settings: IAppSettings = {
  IS_DEVELOPMENT: process.env.NODE_ENV !== 'production',
  IS_BROWSER: typeof window !== 'undefined',
  LOG_LEVEL: 'log', // 'log' (default), 'info' (shows everything) or 'warn' (only shows warnings)
  REMOTE_DEBUGGER: typeof atob !== 'undefined', // true if remote debugger is available so the logger knows what to do
  WARN_ILLEGAL_STATE_MUTATIONS: false, // can have a performance impact, enable only when necessary - automatically disabled in production
  WARN_WHY_DID_YOU_RENDER: false, // can have a performance impact, enable only when necessary - automatically disabled in production
  DEBUG_PERFORMANCE: false, // can have a performance impact, enable only when necessary - automatically disabled in production
  BASE_URL,
  FACEBOOK_URL: 'https://www.facebook.com/becontheapp/',
  INSTAGRAM_URL: 'https://www.instagram.com/becontheapp/',
  LINKEDIN_URL: 'https://www.linkedin.com/company/becontheapp/',
  CONTACT_EMAIL: 'contact@becontheapp.com',
  SUPPORT_EMAIL: 'contact@becontheapp.com',
  CONTACT_PHONE: '+44 (0) 333 050 9420',
  APP_STORE_LINK: 'https://apps.apple.com/app/id1573144834',
  GOOGLE_PLAY_LINK: 'https://play.google.com/store/apps/details?id=uk.co.codeleap.follo',
  MAX_OBJECT_AGE: 60000,
  LAWFUL_BASIS_GLOSSARY: 'https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/lawful-basis-for-processing/',
  WEBSITE_TITLE: 'Becon',
  GOOGLE_ANALYTICS_ID: 'UA-211868346-1',
  GOOGLE_MAPS_API_KEY: 'AIzaSyAsSPDNSLNY65sB5c4ZKoNsYVMAd8O4u44',
  INSTAGRAM_API_TOKEN: 'IGQVJWOGp4anVLZAGxYaXp4T3Q5NXFnQXYtVE9vbExReXd2eHNDcDVOUmlsUDZArb3lDeWdPMUgwRUZAkVmViaUgwRllTSDZAUSEJ2ZAU13cktCVjI2OVJac1VwTUdBeDVaNzQzdEc2WGtyZA0tWbW5iN2hKSAZDZD',

  W3W_TOKEN: 'L6E6FVY2',
}
global.APP = {
  ...global.APP,
  Settings: settings,
}

export default settings
