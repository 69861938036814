function initialiseLinkTree(_windowObj, _document, script, link, functionName, r = {}, ee = {}) {
  _windowObj[functionName] = _windowObj[functionName] || function () { (_windowObj[functionName].q = _windowObj[functionName].q || []).push(arguments) }

  _windowObj[functionName].l = Number(new Date())
  r = _document.createElement(script)
  ee = _document.getElementsByTagName(script)[0]

  r.async = 1
  r.src = link
  ee.parentNode.insertBefore(r, ee)
}

let linkTreeEventSent = false

function linkTreeEvent() {
  if (!linkTreeEventSent && typeof window !== 'undefined') {
    initialiseLinkTree(window, document, 'script', 'https://assets.production.linktr.ee/ltpixel/ltpix.min.js?t=' + 864e5*Math.ceil(new Date()/864e5), 'lti')
    lti('init', 'LTU-a9492060-b5d7-4925-b060-704423551c54')
    lti('pageloaded')
    linkTreeEventSent = true
    log('Linktree events sent')
  }
}

export default {
  linkTreeEvent,
}
