import { React, View, Text, Button, navigate } from 'lib'
import { Theme } from 'app'
import { CSSProperties } from 'react'
import { ArrowLeftIcon } from 'assets/icons'

type PageTitleProps = {
  titleColor?: string,
  canGoBack?: boolean,
  style?: CSSProperties,
} & ({ location: string; title?: string } | { location?: string; title: string })


export const PageTitle: React.FC<PageTitleProps> = ({ location, canGoBack, title, titleColor = Theme.colors.greyscale1, style }) => {
  return (
    <View variant={'flex column'} style={style}>
      <View style={styles.titleWrapper}>
        {canGoBack &&
          <ArrowLeftIcon
            style={styles.icon}
            color={Theme.colors.black}
            onClick={() => navigate(-1)}
          />
        }
        {location && <Text variant={title ? ['h5', 'uppercase'] : ['h3']} text={location} style={styles.title}/>}
      </View>
      {title && <Text variant={['h3', 'marginTop:1', titleColor]} style={{ textTransform: 'capitalize' }} text={title}/>}
    </View>
  )
}

const styles = {
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: Theme.spacing(1),
  },
  title: {
    color: Theme.colors.grad1,
    background: Theme.colors.gradientLinear,
    textDecorationColor: Theme.colors.gradientLinear,
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    display: 'inline-block',
  },
  icon: {
    cursor: 'pointer',
  },
}
