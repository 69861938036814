import { Settings } from "app"

function appendSlash(url) {
  if (!url.includes("?") && url.charAt(url.length - 1) != "/") {
    url += "/"
  }
  return url
}

interface FetchOptions {
  full_url: boolean
  json: boolean
  multipart: boolean
  no_cors: boolean
}

type IFetchParams<R = any> = {
  url: string
  options?: Partial<FetchOptions>
  headers?: HeadersInit
  data?: any
  method?: "GET" | "PUT" | "POST" | "PUT"
  onSuccess?: (data: Response | R, isJson: boolean) => void
  onFailure?: (response: Response | string) => void
}

const defaultOptions: FetchOptions = {
  full_url: false,
  json: true,
  multipart: false,
  no_cors: false,
}

async function Fetch<R extends unknown = any>(params: IFetchParams<R>) {
  info("Fetch", { url: params.url, params })

  let options: FetchOptions = {
    ...defaultOptions,
    ...params.options,
  }

  let AUTH_HEADER

  if (options.multipart) {
    AUTH_HEADER = {
      Accept: "*/*",
      "Content-Type": "multipart/form-data",
    }
  } else {
    AUTH_HEADER = {
      Accept: "application/json",
      "Content-Type": "application/json",
    }
  }

  // const user = firebase.auth().currentUser
  // let idTokenResult = null
  // let token = null
  // if (user) {
  //   idTokenResult = await firebase.auth().currentUser.getIdTokenResult()
  //   token = idTokenResult.token
  // }

  // const AUTH_TOKEN = token
  // if (AUTH_TOKEN) {
  //   AUTH_HEADER.Authorization = AUTH_TOKEN
  // }
  const url = options.full_url
    ? params.url
    : appendSlash(Settings.BASE_URL + params.url)

  const data =
    params.data &&
    (options.multipart ? params.data : JSON.stringify(params.data))
  const headers = params.headers || AUTH_HEADER
  const mode = options.no_cors ? "no-cors" : "cors"
  // log('Fetch', { url, params, data, headers })

  try {
    const response = await fetch(url, {
      method: params.method,
      body: data,
      headers: headers,
      mode: mode,
    })
    // log('response', { url: params.url, params, response })
    if (response && response.ok) {
      if (params.onSuccess) {
        if (options.json) {
          try {
            const json = (await response.json()) as R
            params.onSuccess(json, true)
          } catch (err) {
            warn("> > > > > > INTERNAL ERROR < < < < < <", { err })
            params.onSuccess(null, false)
          }
        } else {
          params.onSuccess(response, false)
        }
      }
    } else {
      const status = response.status
      const bodyText = response?.body
      warn("> > > > > > SERVER ERROR < < < < < <", {
        url,
        status,
        params,
        bodyText,
        response,
        AUTH_HEADER,
      })
      // AppStatus.set(null) TODO implement some loading modal
      if (params.hasOwnProperty("onFailure")) {
        params.onFailure(response)
      } else if (bodyText) {
        warn(bodyText)
        // OSAlert.error({ body: bodyText, onDismiss: () => null })
      }
    }
    return true
  } catch (err) {
    warn("> > > > > > NETWORK ERROR < < < < < <", {
      url,
      err,
      params,
      AUTH_HEADER,
    })
    // AppStatus.set(null) TODO implement some loading modal
    if (params.onFailure) {
      params.onFailure("NETWORK_ERROR")
    }
    return false
  }
}

export default Fetch
