/** @jsx jsx */
import { jsx } from '@emotion/react'
/* eslint no-restricted-imports: 'off' */
import { React, getStyles, useRef } from 'lib'
import { TouchableStyles } from 'app'
import { Link as GatsbyLink } from 'gatsby'
import { CSSInterpolation } from 'types/style'

const styleVariants = { TouchableStyles }

export type AcceptedTouchableVariants = NestedKeys<typeof styleVariants>

/**
 * @exports <Touchable/>
 * Default Touchable component. Use this instead of onClick or hrefs.
 * This is designed to work as a View but with added capabilities.
 *
 * @prop {string} variant ---> Touchable style variants
 * @prop {object} style ---> Touchable styles object
 * @prop {string} to ---> Go to URL when pressed (best for navigation)
 * @prop {function} onPress ---> Execute when pressed (best for triggers)
 * @prop {function} onHover ---> Triggered when hovered, returns bool
 * @prop {object} ...viewProps ---> Also receives all View props
 *
 */

const miniumHoverDurationForReporting = 500

export type TouchableProps = {
  variant?: AcceptedTouchableVariants[] | string
  disabled?: boolean
  style?: CSSInterpolation | CSSInterpolation[]
  href?: string
  to?: string
  onHover?: (isHovering: boolean) => void
  openNewTab?: boolean
  onPress
}

const Touchable: React.ForwardRefRenderFunction<
  HTMLDivElement | HTMLAnchorElement,
  React.PropsWithChildren<TouchableProps>
> = (props, ref) => {
  const {
    children,
    onPress,
    to,
    href,
    disabled,
    style,
    onHover,
    openNewTab,
    ...otherProps
  } = props

  const { variant } = props
  const lastHover = useRef(null)
  const linkTarget = href || to

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!disabled) {
      if (openNewTab && linkTarget) {
        log(
          'Touchable pressed! <Touchable/> opening new tab - event propagation not allowed',
          { props, otherProps },
        )
        window.open(linkTarget)
        stopAllPropagation(event)
      } else if (onPress && linkTarget) {
        log(
          'Touchable pressed! <Touchable/> firing onPress and to or href - event propagation allowed',
          { props, otherProps },
        )
        onPress(event)
      } else if (onPress) {
        log('Touchable pressed! <Touchable/> firing onPress', {
          props,
          otherProps,
        })
        onPress(event)
        stopAllPropagation(event)
      } else if (linkTarget?.startsWith('/#')) {
        log('Touchable pressed! <Touchable/> hashtag click fired', {
          props,
          otherProps,
        })
        // prevents default scroll behaviour when clicking anchors on homepage
        const hash = linkTarget.substring(1)
        const pathname = window.location.pathname
        const isHome = pathname == '/'
        if (isHome) {
          stopAllPropagation(event)
          if (window.history.pushState) {
            window.history.pushState(null, null, hash)
          } else {
            window.location.hash = hash
          }
        }
      } else {
        log(
          'Touchable pressed! No action defined in <Touchable/>, maybe to or href',
          { props, otherProps },
        )
      }
    } else {
      info('Click disabled <Touchable/>')
    }
  }

  const stopAllPropagation = (event) => {
    try {
      event.stopPropagation()
    } catch {
      log('Failed event.stopPropagation()')
    }
    try {
      event.preventDefault()
    } catch {
      log('Failed event.preventDefault()')
    }
    try {
      event.nativeEvent.stopImmediatePropagation()
    } catch {
      log('Failed event.nativeEvent.stopImmediatePropagation()')
    }
  }

  const handleHover = (hover) => {
    if (onHover) onHover(hover)
  }

  const styles = getStyles(variant, styleVariants, false, [
    disabled ? 'disabled' : '',
  ])

  const divStyles = [styles.TouchableStyles, style]

  // const TouchableElement = (to || href) ? ((!to?.startsWith('http') && !href) ? GatsbyLink : 'a') : 'div'
  let TouchableElement = null
  if (linkTarget) {
    if (!to?.startsWith('http') && !href) {
      TouchableElement = GatsbyLink
    } else {
      TouchableElement = 'a'
    }
  } else {
    TouchableElement = 'div'
  }

  return (
    <TouchableElement
      ref={ref}
      to={to}
      href={href || to}
      css={divStyles}
      onClick={handleClick}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
      {...otherProps}
    >
      {children}
    </TouchableElement>
  )
}

const TouchableComponent = React.forwardRef(Touchable)

export default TouchableComponent
