import { Theme } from 'app'
import { Image, Link, React, StyleSheet, Text, View } from 'lib'

export const Item = (props) => {
  const { lastItem } = props

  return (
    <Link to={props.slug} style={styles.item}>
      <View variant={'fullWidth flex'} style={[styles.itemWrapper, !lastItem && styles.divisor]}>
        <View style={styles.imageWrapper}>
          <View style={styles.iconWrapper}>
            <Image source={props.icon} style={styles.icon}/>
          </View>
        </View>

        <View style={styles.contentWrapper}>
          <Text variant={'h4 marginTop:2 marginBottom:1'} text={props.title}/>
        </View>
      </View>
    </Link>
  )
}

const styles = StyleSheet.createComponentStyleSheet({
  wrapper: {
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
  },
  item: {
    margin: '0 !important',
    textDecoration: 'none !important',
  },
  itemWrapper: {
    minHeight: 220,
    overflow: 'hidden',
    [Theme.media.down('small')]: {
      minHeight: 120,
      flexDirection: 'row',
    },
  },
  iconWrapper: {
    display: 'flex',
    objectFit: 'contain',
    padding: Theme.spacing(2),
    backgroundColor: Theme.colors.lightPurple,
    borderRadius: Theme.values.borderRadiusMedium,
  },
  icon: {
    width: 80,
    height: 80,
    objectFit: 'contain',
    [Theme.media.down('small')]: {
      width: 48,
      height: 48,
    },
  },
  divisor: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: Theme.colors.greyscale3,
  },
  imageWrapper: {
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'center',
    [Theme.media.up('small')]: {
      maxWidth: 220,
    },
  },
  contentWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: Theme.spacing(3),
  },
})
