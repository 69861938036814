import { CSSInterpolation } from "types/style"

function flatten(props) {
  if (!props) return {}
  const isArray = Array.isArray(props)
  let result = {}
  if (isArray) {
    for (const i in props) {
      result = { ...result, ...props[i] }
    }
  } else {
    result = props
  }
  return result
}

export type VariantsStyleSheet<T> = {
  default?: CSSInterpolation
} & { [Property in keyof T]: CSSInterpolation }

function createVariants<
  T extends { default?: CSSInterpolation } & Partial<
    Record<string, CSSInterpolation>
  >
>(styles: T): VariantsStyleSheet<T> {
  return styles as VariantsStyleSheet<T>
}
function createComponentStyleSheet<T extends Record<string, CSSInterpolation>>(
  styles: T
): T {
  return styles as T
}

export default {
  createComponentStyleSheet,
  createVariants,
  flatten,
}
