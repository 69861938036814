/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Theme } from 'app'
import { React, Image, View } from 'lib'
import { triggersList } from './TriggersList'

import originPin from 'assets/originPin.png'
import destinationMarker from 'assets/map_pin_v2.png'
import viewerMarker from 'assets/viewerPin.png'
import userMarker from 'assets/userPin.png'

const Polyline: React.FC<{ bgColor: string, borderColor: string }> = ({ bgColor, borderColor }) => {
  return (
    <View style={{
      width: 18,
      height: 32,
      marginLeft: 8,
      marginRight: 6,
      backgroundColor: bgColor,
      border: `4px solid ${borderColor}`,
      borderBottom: 0,
      borderTop: 0,
    }}/>
  )
}

const ICON_SIZE = 32

export const mapCaptions = [
  ...Object.values(triggersList).map(vl => ({ ...vl, isIcon: true })),
  {
    title: 'Origin',
    description: 'The location where the user started their journey.',
    icon: () => <Image source={originPin} style={{ objectFit: 'contain', width: ICON_SIZE, height: ICON_SIZE }}/>,
    isIcon: false,
    show: true,
  },
  {
    title: 'Destination',
    description: 'The destination the user set for their journey.',
    icon: () => <Image source={destinationMarker} style={{ objectFit: 'contain', width: ICON_SIZE, height: ICON_SIZE }}/>,
    isIcon: false,
    show: true,

  },
  {
    title: 'Your Location',
    description: 'Your current location.',
    icon: () => <Image source={viewerMarker} style={{ objectFit: 'contain', width: ICON_SIZE, height: ICON_SIZE }}/>,
    isIcon: false,
    show: true,
  },
  {
    title: 'Actual Location',
    description: 'The latest user location available.',
    icon: () => <Image source={userMarker} style={{ objectFit: 'contain', width: ICON_SIZE, height: ICON_SIZE }}/>,
    isIcon: false,
    show: true,
  },
  {
    title: 'Intended Route',
    description: 'The route the user planned on going through.',
    icon: () => <Polyline bgColor={Theme.colors.grad1} borderColor={Theme.colors.darkDetail}/>,
    isIcon: false,
    show: true,
  },
  {
    title: 'Route Taken',
    description: 'The actual route the user has travelled.',
    icon: () => <Polyline bgColor={Theme.colors.yellowAlert} borderColor={Theme.colors.darkDetail}/>,
    isIcon: false,
    show: true,
  },
  {
    title: 'Offline',
    description: 'The path between where the user has disconnected and reconnected.',
    icon: () => <Polyline bgColor={Theme.colors.greyAlert} borderColor={Theme.colors.darkDetail}/>,
    isIcon: false,
    show: true,
  },
]
