import { InfoBox } from '@react-google-maps/api'


import { Theme } from 'app'
import { React, View, Text, useRedux, onUpdate } from 'lib'
import { LiveTracking } from 'actions'
import { RouteInfo } from 'components'

import { MapPinIcon } from 'assets/icons'
import { motion } from 'types/customMotion'
import { SelectedCoordsMarker } from './SelectedCoordsMarker'

export const RouteDetailsBox: React.FC = () => {
  const { triggerDetails: { visible, data }, map } = useRedux('Map')

  let { trigger } = LiveTracking.useTrigger()
  const Icon = trigger?.icon || MapPinIcon
  const triggerColor = LiveTracking.getTriggerColor(trigger?.type)
  const isSmall = Theme.hooks.down('small')

  const coords = { lat: data?.coords?.lat, lng: data?.coords?.lng }

  if (data?.currentLocation) {
    trigger = {
      ...trigger,
      ...LiveTracking.defaultData.triggers.currentLocation,
    }
  } else if (data?.viewerLocation) {
    trigger = {
      ...trigger,
      ...LiveTracking.defaultData.triggers.viewerLocation,
    }
  }

  onUpdate(() => {
    if (visible && data?.coords) {
      map?.panTo(coords)
    }
  }, [visible, data])

  return (
    <>
      {!trigger?.title && visible ? <SelectedCoordsMarker lat={coords.lat} lng={coords.lng}/> : null}
      {!isSmall ? (
        <InfoBox
          position={coords}
        >
          <motion.View
            variants={box}
            animate={visible ? 'show' : 'init'}
            transition={{ bounce: 0.3 }}
            style={styles.boxWrapper}
            variant={'flex column'}
          >
            {trigger?.title && (
              <View style={[styles.header, { backgroundColor: triggerColor }]}>
                <View variant={'flex alignCenter marginBottom:2'}>
                  <Icon size={24} color={Theme.colors.white}/>
                  <Text variant={`h4 marginLeft:1 white`} text={trigger.title}/>
                </View>
                <Text variant={`p4 white`} text={trigger.description}/>
              </View>
            )}
            {!data?.viewerLocation && <RouteInfo data={data} styles={styles.routeInfoWrapper}/>}
          </motion.View>
        </InfoBox>
      ): null}
    </>
  )
}

const box = {
  init: {
    height: 0,
    opacity: 0,
    duration: 500,

  },
  show: {
    opacity: 1,
    height: 'auto',
    duration: 500,
  },
}

const styles = {
  boxWrapper: {
    width: '100%',
    overflow: 'hidden',
  },
  header: {
    padding: Theme.spacing(2),
  },
  routeInfoWrapper: {
    padding: Theme.spacing(2),
  },
}
