import { keyframes } from "@emotion/react"
import { CSSProperties } from "react"
import {
  themeKeyframes,
  animationDefaults,
  breakpoints,
  themeValues,
  colors,
  themeFonts,
  displayTypes,
  layout,
  styles,
} from "./contants"
import Media from "../../lib/utils/Media"

export type ThemeKeyframes = Record<
  keyof typeof themeKeyframes,
  ReturnType<typeof keyframes>
>

export type AnimationParams = {
  seconds: number
  timing: CSSProperties["animationTimingFunction"]
  duration: CSSProperties["animationDuration"]
}
export type GetAnimationsArgument = {
  params?: AnimationParams
  name?: keyof ThemeKeyframes
}

export function getAnimations(
  args?: Required<GetAnimationsArgument>
): Record<keyof ThemeKeyframes, string>
export function getAnimations(args?: GetAnimationsArgument): string
export function getAnimations(args?: GetAnimationsArgument) {
  if (args?.name) {
    if (args.params) {
      return `${themeKeyframes[args.name]} ${args.params.duration}s ${
        args.params.timing
      } ${args.params.duration}`
    }
  } else {
    return animationDefaults
  }
}
export type CircleFnReturn = Pick<
  CSSProperties,
  "height" | "width" | "overflow" | "borderRadius"
>

export type AppTheme = {
  breakpoints: typeof breakpoints
  values: typeof themeValues
  colors: typeof colors
  keyframes: ThemeKeyframes
  media: typeof Media.queries
  hooks: typeof Media.hooks
  animations: typeof getAnimations
  headerHeight(): number
  spacing(size: number): number
  circle(size: number): CircleFnReturn
  squircle(size: number): CircleFnReturn
  semiRound(size: number): CircleFnReturn
  square(size: number): Pick<CSSProperties, "width" | "height">
  paddingHorizontal(
    size: number,
    absolute?: boolean
  ): Pick<CSSProperties, "paddingLeft" | "paddingRight">
  paddingVertical(
    size: number,
    absolute?: boolean
  ): Pick<CSSProperties, "paddingTop" | "paddingBottom">
  marginHorizontal(
    size: number,
    absolute?: boolean
  ): Pick<CSSProperties, "marginLeft" | "marginRight">
  marginVertical(
    size: number,
    absolute?: boolean
  ): Pick<CSSProperties, "marginTop" | "marginBottom">
  debug(
    color?: CSSProperties["color"]
  ): Pick<CSSProperties, "borderStyle" | "borderWidth" | "borderColor">
  borderColor(
    color?: CSSProperties["color"]
  ): Pick<
    CSSProperties,
    | "borderTopColor"
    | "borderBottomColor"
    | "borderLeftColor"
    | "borderRightColor"
  >
  pageConstraintsMargin: Pick<CSSProperties, "marginLeft" | "marginRight">
  pageConstraintsPadding: Pick<CSSProperties, "paddingLeft" | "paddingRight">
} & typeof themeFonts &
  typeof displayTypes &
  typeof layout &
  typeof styles
