import { Reducer } from "redux"

export type AppStatusState = {
  activity: "loading" | "idle"
  network: "ok" | "notOk"
  fetchQueue: BaseStoreItem[]
}

const initialState: AppStatusState = {
  activity: "loading",
  network: "ok",
  fetchQueue: [],
}

export const AppStatus: Reducer<AppStatusState, AppStatusActions> = (
  state = initialState,
  action
) => {
  const newState = Object.assign({}, state)
  switch (action.type) {
    case "SET_APP_ACTIVITY_STATUS":
      newState.activity = action.activity
      return newState
    case "SET_NETWORK_STATUS":
      newState.network = action.network
      return newState
    case "SET_NETWORK_QUEUE":
      newState.fetchQueue = action.fetchQueue
      return newState
    default:
      return state
  }
}

export type AppStatusActions =
  | {
      type: "SET_APP_ACTIVITY_STATUS"
      activity: AppStatusState["activity"]
    }
  | {
      type: "SET_NETWORK_STATUS"
      network: AppStatusState["network"]
    }
  | {
      type: "SET_NETWORK_QUEUE"
      fetchQueue: AppStatusState["fetchQueue"]
    }
