import Cookies from "js-cookie"

function get(key: string) {
  return Cookies.get(key)
}

function set(key: string, value: string | object) {
  return Cookies.set(key, value, { expires: 365 })
}

function remove(key: string) {
  return Cookies.remove(key)
}

export default {
  get,
  set,
  remove,
}
