import moment from "moment"
import { Settings } from "app"
import { connect } from "react-redux"

function addOrUpdate<T extends BaseStoreItem>(state: T[], newObject: T) {
  let updated = false
  let newState = [...state]
  for (const s in state) {
    if (state[s].id == newObject.id) {
      state[s] = newObject
      updated = true
    }
  }
  if (!updated) {
    newState.push(newObject)
  }
  return newState
}

function merge<T extends BaseStoreItem>(state: T[], arr: T | T[]) {
  if (Array.isArray(arr)) {
    if (arr.length) {
      for (const a in arr) {
        const obj = arr[a]
        if (obj) {
          obj.receivedAt = moment()
          addOrUpdate(state, arr[a])
        }
      }
      if (state[0].id) {
        state.sort((a, b) => b.id - a.id)
      }
    }
  } else {
    const obj = arr
    if (obj) {
      obj.receivedAt = moment()
      addOrUpdate(state, obj)
    }
  }
  return state
}

function remove<T extends BaseStoreItem>(state: T[], id: number) {
  const newState = []
  for (const s in state) {
    if (state[s].id != id) {
      newState.push(state[s])
    }
  }
  return newState
}

function isExpired<T extends BaseStoreItem>(obj) {
  const age = moment().diff(obj.receivedAt, "seconds")
  if (age > Settings.MAX_OBJECT_AGE) {
    return true
  } else {
    return false
  }
}

// const use = (state, props) => {
//   return {
//     profile: state.Profiles.filter(p => p.id == props.profileId),
//   }
// }

export function withRedux(use, component) {
  let mapStateToProps = use
  if (typeof use != "function") {
    mapStateToProps = (state) => {
      const result = {}
      for (const k in use) {
        const key = use[k]
        result[key] = state[key]
      }
      return result
    }
  }
  return connect(mapStateToProps)(component)
}

export default {
  addOrUpdate,
  merge,
  remove,
  isExpired,
  withRedux,
}
