import { Button, React, Touchable, useTheme, View } from 'lib'
import Switch from 'react-switch'
import { Theme } from 'app'
import { SunIcon } from 'assets/icons'
import { MoonIcon } from 'assets/icons'

type IconProps = {
  isHandleIcon?: boolean;
  theme: 'light' | 'dark';
}

const SunIconComponent: React.FC<IconProps> = ({ isHandleIcon, theme }) => {
  const styles = getStyles(theme)
  const isLight = theme === 'light'

  return (
    <View variant={'alignCenter justifyCenter row'} style={isHandleIcon ? styles.handleIconWrapper : styles.switchIcon}>
      <SunIcon size={24} color={isLight ? Theme.colors.greyscale2 : Theme.colors.white}/>
    </View>
  )
}

const MoonIconComponent: React.FC<IconProps> = ({ isHandleIcon, theme }) => {
  const styles = getStyles(theme)
  const isLight = theme === 'light'

  return (
    <View variant={'alignCenter justifyCenter row'} style={isHandleIcon ? styles.handleIconWrapper : styles.switchIcon}>
      <MoonIcon size={24} color={isLight ? Theme.colors.greyscale2 : Theme.colors.white}/>
    </View>
  )
}

export const ThemeSwitch: React.FC = () => {
  const { isLight, isDark, setTheme, theme: currentTheme } = useTheme()
  const isSmall = Theme.hooks.down('small')
  const styles = getStyles(currentTheme)

  const offColor = isLight ? Theme.colors.greyscale7 : Theme.colors.greyscale12
  const onColor = isLight ? Theme.colors.greyscale9 : Theme.colors.greyscale12
  const onHandleColor = isLight ? Theme.colors.greyscale9 : Theme.colors.greyscale13
  const offHandleColor = isLight ? Theme.colors.greyscale7 : Theme.colors.greyscale13

  if (isSmall) {
    return (
      <Touchable style={styles.themeButton} variant={'padding:2'} onPress={() => setTheme(currentTheme)}>
        {isLight ? (
          <SunIcon size={24} color={isLight ? Theme.colors.greyscale2 : Theme.colors.white}/>
        ) : (
          <MoonIcon size={24} color={isLight ? Theme.colors.greyscale2 : Theme.colors.white}/>
        )}
      </Touchable>
    )
  } else {
    return (
      <Switch
        checked={isDark}
        onChange={() => setTheme(currentTheme)}
        height={42}
        width={80}
        handleDiameter={40}
        offColor={offColor}
        onColor={onColor}
        onHandleColor={onHandleColor}
        offHandleColor={offHandleColor}
        checkedIcon={<SunIconComponent theme={currentTheme}/>}
        uncheckedIcon={<MoonIconComponent theme={currentTheme}/>}
        checkedHandleIcon={<MoonIconComponent isHandleIcon theme={currentTheme}/>}
        uncheckedHandleIcon={<SunIconComponent isHandleIcon theme={currentTheme}/>}
      />
    )
  }
}

const getStyles = (theme: IconProps['theme']) => ({
  switchIcon: {
    height: '100%',
    width: '100%',
    background: theme === 'light' ? Theme.colors.greyscale8 : Theme.colors.greyscale12,
    boxShadow: '0px 4px 24px ' + Theme.colors.black + '40',
    backdropFilter: 'blur(5px)',
    borderRadius: 999,
  },
  handleIconWrapper: {
    height: '100%',
    width: '100%',
  },
  themeButton: {
    background: theme === 'light' ? Theme.colors.greyscale8 : Theme.colors.greyscale12,
    borderRadius: '50%',

  },
})
