import {
  themeFonts,
  themeValues,
  themeKeyframes,
  displayTypes,
  layout,
  styles,
  colors,
  breakpoints,
} from './contants'
import { AppTheme, getAnimations } from './types'
import Media from '../../lib/utils/Media'
export const Theme: AppTheme = {
  ...themeFonts,
  breakpoints,
  values: themeValues,
  colors,
  keyframes: themeKeyframes,
  animations: getAnimations,
  media: {
    ...Media.queries,
  },
  hooks: {
    ...Media.hooks,
  },
  headerHeight: function () {
    return this.values.headerBannerHeight + this.values.headerMenuHeight
  },
  spacing: function (size) {
    return size * this.values.spacing
  },
  circle: (side) => ({
    width: side,
    height: side,
    borderRadius: side / 2,
    overflow: 'hidden',
  }),
  squircle: (side) => ({
    width: side,
    height: side,
    borderRadius: 8,
    overflow: 'hidden',
  }),
  semiRound: (side) => ({
    width: side,
    height: side,
    borderRadius: side / 3,
    overflow: 'hidden',
  }),
  square: (side) => ({
    width: side,
    height: side,
  }),
  paddingHorizontal: function (size, absolute = false) {
    size = absolute ? size : this.spacing(size)
    return {
      paddingLeft: size,
      paddingRight: size,
    }
  },
  paddingVertical: function (size, absolute = false) {
    size = absolute ? size : this.spacing(size)
    return {
      paddingTop: size,
      paddingBottom: size,
    }
  },
  marginHorizontal: function (size, absolute = false) {
    size = absolute ? size : this.spacing(size)
    return {
      marginLeft: size,
      marginRight: size,
    }
  },
  marginVertical: function (size, absolute = false) {
    size = absolute ? size : this.spacing(size)
    return {
      marginTop: size,
      marginBottom: size,
    }
  },
  debug: (color = 'red') => ({
    borderColor: color,
    borderWidth: 1,
    borderStyle: 'dotted',
  }),
  borderColor: (color = 'rgba(0, 0, 0, 1)') => ({
    borderTopColor: color,
    borderBottomColor: color,
    borderLeftColor: color,
    borderRightColor: color,
  }),
  get pageConstraintsMargin() {
    return {
      ...this.marginHorizontal(10),
      [Media.queries.down('mid')]: {
        ...this.marginHorizontal(4),
      },
    }
  },
  get pageConstraintsPadding() {
    return {
      ...this.paddingHorizontal(10),
      [Media.queries.down('mid')]: {
        ...this.paddingHorizontal(4),
      },
    }
  },
  ...displayTypes,
  ...layout,
  ...styles,
}
