import { View, React, Text, Touchable, useRedux } from 'lib'
import { Theme } from 'app'
import { MapPinIcon, ArrowUpIcon, CloseIcon } from 'assets/icons'
import { motion } from 'types/customMotion'
import { LiveTracking, Map } from 'actions'

type CardHeaderProps = {
  isTrigger?: boolean,
  visible: boolean,
  noRadius?: boolean,
  setVisible: (param: boolean) => void
}

export const CardHeader: React.FC<CardHeaderProps> = ({ isTrigger, visible, setVisible, noRadius }) => {
  const { triggerDetails } = useRedux('Map')
  const { route, userRoute } = useRedux('LiveTracking')

  const isSmall = Theme.hooks.down('small')

  const { trigger } = LiveTracking.useTrigger()
  const Icon = trigger?.icon || MapPinIcon
  const routeEnded = route?.active === false
  const alertTriggered = route?.alert_sent || userRoute.find((routeData) => routeData?.sosTriggered)
  const showMobileTrigger = isTrigger && triggerDetails?.data && isSmall
  const radius = (visible && noRadius) ? 0 : Theme.values[isSmall ? 'borderRadiusLarge' : 'borderRadiusMedium']
  const border = { borderTopLeftRadius: radius, borderTopRightRadius: radius }

  let title, bgColor = { backgroundColor: '' }

  if (showMobileTrigger) {
    title = trigger?.title || LiveTracking.defaultData.triggers.locationAnyData.title
    bgColor.backgroundColor = LiveTracking.getTriggerColor(trigger?.type)
  } else {
    title = routeEnded ? 'Tracking Ended' : alertTriggered ? 'Alert Triggered' : 'Live Tracking'
    bgColor.backgroundColor = routeEnded ? Theme.colors.greyAlert :
      alertTriggered ? Theme.colors.redStatus : Theme.colors.greenStatus
  }

  if (showMobileTrigger && triggerDetails.data.currentLocation) {
    title = LiveTracking.defaultData.triggers.currentLocation.title
  }

  function clear() {
    if (isSmall) {
      setVisible(false)
      Map.setHasExpandedTrigger(false)
    }

    Map.clear(triggerDetails?.data, () => setVisible(false))
  }

  return (
    <View variant={'paddingHorizontal:3 paddingVertical:2 row alignCenter justifySpaceBetween'} style={[styles.header, border, bgColor]}>
      <View variant={'row alignCenter'} style={styles.headerInnerWrapper}>
        {showMobileTrigger && Icon ? <Icon size={24} color={Theme.colors.white}/> : null}
        <Text variant={`h4 white capitalize`} text={title}/>
      </View>

      {isSmall && (
        <View variant={'row alignCenter'} style={styles.headerInnerWrapper}>
          <motion.View
            variants={arrowAnimation}
            animate={visible ? 'rotate' : 'init'}
          >
            <Touchable onPress={() => {
              setVisible(!visible)
              if (isSmall) Map.setHasExpandedTrigger(!visible)
            }}>
              <ArrowUpIcon size={32} color={Theme.colors.white}/>
            </Touchable>
          </motion.View>

          {showMobileTrigger && (
            <Touchable onPress={clear}>
              <CloseIcon size={24} color={Theme.colors.white}/>
            </Touchable>
          )}
        </View>
      )}
    </View>
  )
}

const arrowAnimation = {
  init: {
    rotate: 0,
    duration: 50,
  },
  rotate: {
    rotate: 180,
    duration: 50,
  },
}

export const CARD_TRIGGER_HEIGHT = '70px'

const styles = {
  header: {
    width: '100%',
    height: CARD_TRIGGER_HEIGHT,
    maxHeight: CARD_TRIGGER_HEIGHT,
    minHeight: CARD_TRIGGER_HEIGHT,
  },
  headerInnerWrapper: {
    gap: Theme.spacing(2),
  },
}
