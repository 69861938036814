/** @jsx jsx */
import { jsx } from '@emotion/react'
import { React, getStyles } from 'lib'
import { StaticQuery, graphql } from 'gatsby'
import { default as GatsbyImage } from 'gatsby-image/withIEPolyfill'
import { ImageStyles, Settings, Theme } from 'app'
import { CSSInterpolation } from 'types/style'

/**
 * @exports <Image/>
 * Simple image component that uses gatsby sourcery internally
 *
 * @prop {string} src or source ---> Selected  animation value
 * @prop {object} style ---> Image style\
 *
 */

const dynamicAssetPrefixes = ['http', 'https', 'blob']

type AcceptedVariants = NestedKeys<typeof ImageStyles>

type ImageProps = {
  source: string
  style?: CSSInterpolation
  alt?: string
  img?: string
  rel?: string
  variant?: string | AcceptedVariants[]
}

const Image: React.FC<ImageProps> = (props) => {
  const source = Tools.parseSourceUrl(props.source)
  if (!props.alt) {
    log('missing alt property in img', { source })
  }
  const isStatic =
    !props.img &&
    dynamicAssetPrefixes.filter((i) => source?.startsWith(i)).length < 1

  const styles = getStyles(props.variant, ImageStyles)

  return (
    <img
      {...props}
      style={null}
      src={source}
      css={[styles.image, props.style]}
    />
  )
}

export default Image
