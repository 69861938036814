import { AvatarStyles } from 'app'
import { React, Touchable, View, Text, Image, getStyles } from 'lib'
import { CSSProperties } from 'react'
import { CSSInterpolation } from 'types/style'

export interface AvatarComponentProps {
  profile: Partial<Record<'avatar' | 'id' | 'first_name' | 'last_name', string>>;
  variant?: string;
  style?: CSSInterpolation | CSSInterpolation[] | Partial<CSSProperties>;
  onPress?: () => void;
}


const AvatarComponent: React.FC<AvatarComponentProps> = (props) => {
  const styles = getStyles(props.variant, AvatarStyles)
  const profile = props.profile

  const getLabelColor = (hex) => {
    const color = Tools.hexToRgb(hex) as Record<'r' | 'g' | 'b', number>
    const brightness = ((color.r * 299) + (color.g * 587) + (color.b * 114)) / 1000
    const threshold = 170
    if (brightness > threshold) {
      return '#444'
    } else {
      return 'white'
    }
  }

  const getLetterFromName = (pos: number) => {
    const name = profile.first_name
    const start = pos
    const end = start + 1
    if (name) {
      return name.substring(start, end)
    } else {
      return null
    }
  }

  const renderImage = () => {
    if (!profile.avatar) {
      const initial = getLetterFromName(0)
      const third = getLetterFromName(2)
      const color = Tools.matchLetterToColor(third)
      const labelColor = getLabelColor(color)
      return (
        <View style={[styles.image, { backgroundColor: color }]}>
          <Text text={initial} style={[styles.text, { color: labelColor }]}/>
        </View>
      )
    } else {
      return (
        <Image style={[styles.image]} source={profile.avatar}/>
      )
    }
  }

  const image = renderImage()
  const press = props?.onPress

  const onPress = () => {
    press()
  }
  const disabled = !press

  return (
    <Touchable style={[AvatarStyles.general.wrapper, styles.image, props?.style]}
      disabled={disabled}
      onPress={onPress}>
      {image}
    </Touchable>
  )
}

export default AvatarComponent
