/* eslint no-restricted-imports: 'off' */
// import { Session  as inspectorSession} from "inspector"
import { Cookies } from 'lib'
import { Reducer } from 'redux'

type SessionState = {
  userAnalyticsId: string;
  cookiesAccepted: string;
  theme: 'light' | 'dark';
}

const initialState: SessionState = {
  userAnalyticsId: null,
  cookiesAccepted: Cookies?.get('Session.cookiesAccepted'),
  theme: 'light',
}

export const Session: Reducer<SessionState, SessionActions> = (
  state = initialState,
  action,
) => {
  const newState = Object.assign({}, state)
  switch (action.type) {
    case 'ACCEPT_COOKIES':
      newState.cookiesAccepted = 'true'
      return newState
    case 'SET_USER_ANALYTICS_ID':
      newState.userAnalyticsId = action.data.userAnalyticsId
      return newState
    case 'SET_THEME':
      newState.theme = action.data.theme
      return newState
    default:
      return state
  }
}

export type SessionActions =
  | {
      type: 'ACCEPT_COOKIES'
      data?: never
    }
  | {
      type: 'SET_USER_ANALYTICS_ID'
      data?: {
        userAnalyticsId: string
      }
    }
  | {
      type: 'SET_THEME'
      data?: {
        theme: SessionState['theme']
      }
  }

