import Fetch from './Fetch'

type contactFormData = Record<'name' |'email'|'description', string>

interface ContactFormActions {
  submit(Params: { data: contactFormData, onSuccess: () => void, onFailure: () => void }): Promise<void>
}

export const submit: ContactFormActions['submit'] = async ({ data, onFailure, onSuccess }) => {
  await Fetch({
    url: 'crm/suggestion/',
    method: 'POST',
    data,
    options: {
      json: false,
    },
    onSuccess: (res) => {
      onSuccess()
    },
    onFailure: (err) => {
      warn(err)
      onFailure()
    },
  })
}

export default {
  submit,
}
