import { View, CenterWrapper, React, useRef, StyleSheet, Analytics } from 'lib'
import { Header, Footer } from 'components'
import SEO from '../managers/SEO'
import { useTimeout } from 'beautiful-react-hooks'
import 'app/stylesheets/css/Global.css'
import { Theme } from 'app'

const Page: CodeleapPage = (props, ref) => {
  const mounted = useRef(false)

  useTimeout(() => {
    if (!mounted.current) {
      log('Mount page', { pathname: window.location.pathname })
      Analytics.pageView(window.location.pathname)
      mounted.current = true
    }
  }, 1000)

  return (
    <>
      <SEO root={props?.root}/>
      <View
        id={props.id}
        style={[
          styles.wrapper,
          props.whiteBackground && styles.whiteBackground,
        ]}
      >
        <Header alwaysDark={props.whiteBackground}/>
        <View style={[props.center && styles.center]}>{props.children}</View>
        <Footer/>
      </View>
    </>
  )
}

const styles = StyleSheet.createComponentStyleSheet({
  wrapper: {
    minHeight: '100vh',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  whiteBackground: {
    backgroundColor: Theme.colors.white,
  },
  center: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
})

const PageComponent = React.forwardRef(Page)
export default PageComponent
