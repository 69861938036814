import { Polyline } from '@react-google-maps/api'
import { Map } from 'actions'
import { Theme } from 'app'
import { React, useRedux } from 'lib'
import { POLYLINE_WEIGHT } from './weight'

type PolylineProps = {mapMarkers: {lat: number, lng: number}[]}

export const BeconPolyline = (props: PolylineProps) => {
  const { mapMarkers } = props
  const { routeEvents } = useRedux('LiveTracking')
  const isSmall = Theme.hooks.down('small')

  function polylinePress(event: google.maps.MapMouseEvent) {
    const lat =event.latLng.lat()
    const lng = event.latLng.lng()
    const closestPoint = mapMarkers.reduce((prev, curr, currIdx) => {
      const distance = Tools.getDistance({ lat: curr.lat, lng: curr.lng }, { lat: lat, lng: lng })
      // const x = lat - curr.lat
      // const y = lng - curr.lng
      // const distance = Math.sqrt(x * x + y * y)

      return distance < prev.value ? { index: currIdx, value: distance } : prev
    }, { index: null, value: 1000 })

    Map.triggerPress({ ...routeEvents[closestPoint.index], coords: { lat, lng } }, isSmall)
  }

  const weight = POLYLINE_WEIGHT[isSmall ? 'mobile' : 'default'].becon

  return (
    <>
      <Polyline
        path={mapMarkers}
        onClick={polylinePress}
        options={{
          geodesic: true,
          strokeColor: Theme.colors.darkDetail,
          strokeOpacity: 1.0,
          strokeWeight: weight.primary,
        }}
      />
      <Polyline
        path={mapMarkers}
        onClick={polylinePress}
        options={{
          geodesic: true,
          strokeColor: Theme.colors.yellowAlert,
          strokeOpacity: 1.0,
          strokeWeight: weight.secondary,
          clickable: true,
        }}
      />
    </>
  )
}
