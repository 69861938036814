import { Settings, Theme } from 'app'

const styles = {
  wrapper: {
    zIndex: 10,
    display: 'flex',
    color: 'white',
    minHeight: Theme.values.footerHeight,
    background: Theme.colors.greyscale1,
    [Theme.media.down('mid')]: {
      paddingTop: Theme.spacing(4),
      paddingBottom: Theme.spacing(4),
    },
  },
  footerLinkWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    [Theme.media.down('mid')]: {
      ...Theme.center,
      ...Theme.flex,
      flexDirection: 'column',
    },
  },
  linkCol: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-around',
    marginRight: Theme.spacing(4),
    [Theme.media.down('mid')]: {
      ...Theme.center,
      ...Theme.flex,
      marginRight: Theme.spacing(2),
      marginLeft: Theme.spacing(2),
    },
  },
  link: {
    fontWeight: 'inherit',
    textDecorationLine: 'none',
    textDecorationColor: '#ffffff !important',
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecorationStyle: 'solid',
      textDecorationLine: 'underline',
      opacity: 1,
    },
  },
  innerWrapper: {
    paddingVertical: Theme.spacing(4),
    flex: 1,
    [Theme.media.down('mid')]: {
      ...Theme.flex,
      ...Theme.center,
      flexDirection: 'column',
    },
  },
  innerWrapperRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    display: 'flex',
    flex: 1,
  },
  innerWrapperColumn: {
    ...Theme.flex,
    ...Theme.center,
    flexDirection: 'column',
  },
  center: {
    ...Theme.center,
  },
  right: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  text: {
    fontSize: 16,
  },
  regDetailsText: {
    color: '#fff',
    opacity: 0.7,
    fontSize: 14,
    marginTop: Theme.spacing(2),
    [Theme.media.down('mid')]: {
      textAlign: 'center',
    },
  },
  inherit: {
    margin: 'inherit',
  },
  footerNavText: {
    marginTop: Theme.spacing(1),
    marginBottom: Theme.spacing(1),
    color: 'white',
  },
  logoImage: {
    width: 120,
    flexShrink: 0,
    marginBottom: Theme.spacing(2),
  },
  mobileDisclaimers: {
    flex: 1,
    marginBottom: Theme.spacing(2),
  },
  contactWrapper: {
    // justifyContent: 'space-around',
    display: 'flex',
    // flex: 1,
    // backgroundColor: 'green',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'column',
    [Theme.media.down('mid')]: {
      // justifyContent: 'flex-start',
      ...Theme.center,
    },
  },
  disclaimersWrapper: {
    // ...Theme.center,
    display: 'flex',
    flexDirection: 'column',
    flex: 3,
    [Theme.media.down('mid')]: {
      // justifyContent: 'flex-start',
      ...Theme.center,
    },
  },
  menuWrapper: {
    // display: 'flex',
    // flex: 1,
  },
  separator: {
    backgroundColor: 'white',
    height: 1,
    marginTop: Theme.spacing(3),
    marginBottom: Theme.spacing(1),
    width: '100%',
  },
  bottomRow: {
    ...Theme.row,
    justifyContent: 'space-between',
  },
  socialMediaWrapper: {
    marginTop: Theme.spacing(2),
  },
  socialMediaIconWrapper: {
    marginLeft: Theme.spacing(2),
    [Theme.media.down('mid')]: {
      marginLeft: Theme.spacing(1),
      marginRight: Theme.spacing(1),
    },
  },
}

export default styles
