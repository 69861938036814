import { Text, React, Touchable, normalizeArrayVariants } from 'lib'
import { CSSInterpolation } from 'types/style'

export type LinkProps = {
  to?: string
  href?: string
  onPress?: React.EventHandler<React.SyntheticEvent<HTMLDivElement, MouseEvent>>
  text?: string
  msg?: string
  variant?: Parameters<typeof Touchable>[0]['variant']
  textVariant?: Parameters<typeof Text>[0]['variant']
  openNewTab?: boolean
  style?: Parameters<typeof Touchable>[0]['style']
}
/**
 * @exports <Link/>
 * Very simple Text wrapped with Touchable compnent
 *
 * @prop {string} text ---> Actual content
 * @prop {string} variant ---> Touchable style variants
 *
 */

const Link: React.FC<LinkProps> = (rawProps) => {
  const { to, href, onPress, openNewTab, children, textVariant, ...props } =
    rawProps

  const textProps = {
    ...props,
    variant: `link ${normalizeArrayVariants(textVariant)}`,
  }

  const touchableProps = children && props

  const content = props.text || props.msg ? <Text {...textProps}/> : children

  return (
    <Touchable
      {...touchableProps}
      openNewTab={openNewTab}
      to={to}
      href={href}
      onPress={onPress}
    >
      {content}
    </Touchable>
  )
}

export default Link
