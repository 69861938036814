import { Theme } from '../index'

import StyleSheet from '../../lib/utils/StyleSheet'

const image = StyleSheet.createVariants({
  default: {
    backgroundColor: Theme.colors.light,
    ...Theme.center,
    ...Theme.circle(140),
    overflow: 'visible',
    objectFit: 'cover',
    opacity: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  large: {
    ...Theme.circle(220),
  },
  medium: {
    ...Theme.circle(90),
  },
  small: {
    ...Theme.circle(48),
  },
  semiRound: {
    ...Theme.semiRound(45),
  },
})

// NOTE: Keep all available properties here even if empty
const text = StyleSheet.createVariants({
  default: {
    fontSize: 26,
  },
  large: {
    fontSize: 150,
    lineHeight: 160,
  },
  mediums: {
    fontSize: 40,
    lineHeight: 50,
  },
  small: {
    fontSize: 20,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
})

// General styles not related to classes
const general = StyleSheet.createVariants({
  default: {},
  wrapper: {
    overflow: 'visible',
    position: 'relative',
  },
  editImageBubble: {
    overflow: 'visible',
    backgroundColor: Theme.colors.primary,
    position: 'absolute',
    ...Theme.circle(50),
    ...Theme.center,
    display: 'flex',
    top: 0,
    right: -5,
  },
  editImageIcon: {
    ...Theme.center,
  },
  fileInput: {
    display: 'none',
  },
})

export default {
  image,
  text,
  general,
}
