import validator from 'validator'

export const truish = (input) => {
  const isValid = input && Boolean(input)
  return isValid ? null : 'Please enter a valid value'
}

export const field = (input) => {
  const isValid = Boolean(input && input.length >= 3)
  return isValid ? null : 'Please enter a valid value'

}

export const email = (input) => {
  const isValid = (input && validator.isEmail(input))

  return isValid ? null : 'Please enter a valid email address'
}

export const username = (input) => {
  const regex = /^[a-z0-9-_]+$/
  const isValid = Boolean(input.match(regex))

  return isValid ? null : 'Please enter a valid username'
}

export const password = (input) => {
  const isValid = input.length >= 6
  return isValid ? null : 'Please enter a valid password'
}

export const phone = (input) => {
  const regex = /^[0-9-+()]*$/
  const isValid = Boolean(input.match(regex)) && input.length > 9

  return isValid ? null : 'Please enter a valid phone number'
}

export default {
  field,
  email,
  truish,
  username,
  password,
  phone,
}
