/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Theme, TextStyles } from 'app'

import { useState, getStyles, isLowBrowserCompatibility } from 'lib'
import { ReactNode, CSSProperties } from 'react'
import { WordReveal } from 'react-text-reveal'

import VisibilitySensor from 'react-visibility-sensor'
import { CSSInterpolation } from 'types/style'

export type AcceptedTextVariants = NestedKeys<typeof TextStyles>

type DivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

type TextProps = DivProps & {
  variant?: AcceptedTextVariants[] | string
  style?: CSSInterpolation | CSSInterpolation[] | Partial<CSSProperties>
  text?: ReactNode
  onPress?: React.EventHandler<React.SyntheticEvent<HTMLDivElement, MouseEvent>>
  msg?: ReactNode
}

/**
 * @exports <Text/>
 * Standard Text component. Use this for all typography.
 *
 * @prop {string} text ---> Actual content
 * @prop {string} variant ---> Text style variants, h1, p1, etc...
 * @prop {object} style ---> Text styles object
 *
 */
const Text: React.FC<TextProps> = (rawProps) => {
  const { onPress, style, text, children, variant, ...props } = rawProps

  Tools.rejectProps({ onPress })

  const lowCompatibility = isLowBrowserCompatibility()

  const [visible, setVisible] = useState(false)

  const fadeIn = variant?.includes('fadeIn')

  const content = children || text

  const styles = getStyles(variant, TextStyles, true)

  const textStyles = [
    styles.text,
    lowCompatibility && styles.compatibility,
    style,
  ]

  // onMount(() => {
  //   setVisible(false)
  // })

  function onChangeVisibility(arg) {
    if (arg) setVisible(arg)
  }

  if (fadeIn) {
    return (
      <VisibilitySensor onChange={onChangeVisibility} partialVisibility={true}>
        <div css={textStyles}>
          <WordReveal
            canPlay={visible}
            animateOpacity={true}
            wordOffsetDelay={55}
            copy={[content]}
            offset={'12px'}
            ease={'cubic-bezier(0,0.4,0.4,1)'}
          >
            {content}
          </WordReveal>
        </div>
      </VisibilitySensor>
    )
  } else {
    return (
      <div {...props} css={textStyles}>
        {content}
      </div>
    )
  }
}

export default Text
