/** @jsx jsx */
import { jsx } from '@emotion/react'

import { React, View, Text, useState, Touchable, useRef, onUpdate } from 'lib'
import { InputStyles } from 'app'
import TextareaAutosize from 'react-autosize-textarea'

/**
 * <Input/>
 *
 * [Required props]
 * @prop {string} value ---> Current field value
 * @prop {function} onChange ---> When text updates
 *
 * [Optional props]
 * @prop {string} label ---> Display label text
 * @prop {object} style ---> Styles to be applied to wrapper
 * @prop {object} textStyle ---> Styles to be applied to text
 * @prop {string} required ---> Whether the field is required
 * @prop {function} validate ---> Validate function - return error message if invalid or null if valid
 */

function Input(rawProps) {
  // NOTE Start by declaring what the component is mostly dependent on, and which variables
  // Best to start defining what's going to affect the state of the components and describe the main props being used

  const {
    style,
    textStyle,
    value,
    label,
    labelMsg,
    onChange,
    onChangeText,
    validate,
    variant,
    required,
    type,
    multiline,
    debug,
    inputRef,
    onFocus,
    onBlur,
    onKeyDown,
    validatorMessage,
    gaLabel,
    gaAction,
    edited,
    disabled,
    reset,
    ...props
  } = rawProps

  const [hover, setHover] = useState(false)
  const [focus, setFocus] = useState(false)
  const [editedState, setEdited] = useState(edited)

  const input = useRef(null)

  // NOTE Then define the functions
  const handleChange = (event) => {
    const text = event.target.value
    if (onChange) onChange(event)
    if (onChangeText) onChangeText(text)
  }

  const handlePress = () => {
    if (inputRef) {
      inputRef.current.focus()
    } else {
      input.current.focus()
    }
  }

  const handleBlur = () => {
    if (!editedState && value) setEdited(true)
    setFocus(false)

    if (onBlur) {
      onBlur()
    }
  }

  const handleFocus = () => {
    setFocus(true)
    if (onFocus) {
      onFocus()
    }
  }

  onUpdate(() => {
    if (edited) {
      setEdited(true)
    }
  }, [edited])

  onUpdate(() => {
    setEdited(false)
  }, [reset])

  // NOTE After functions define the variables to be used in components
  // It's best to be near components so they can be visualised at the same time
  const validationMsg = rawProps.hasOwnProperty('validate')
    ? typeof validate == 'function'
      ? validate(value)
      : validate
    : null
  const validateError = validationMsg || null

  let animate = 'default'
  if (hover) animate = 'hover'
  if (value) animate = 'populated'
  if (focus) animate = 'focus'
  if (disabled) animate = 'disabled'
  if (validateError && editedState && !disabled) animate = 'invalid'

  // NOTE If there are any sub render functions, these should be declared right before the final render method

  const InputElement = multiline ? TextareaAutosize : 'input'

  if (debug) {
    log({ props, rawProps, animate, validate, validateError, validationMsg })
  }

  const inputVariantValues = Object.keys(InputStyles.wrapper)
  // const intersect = variant && inputVariantValues.filter(v => variant.split(' ').includes(v))
  const intersect =
    variant && Tools.getIntersection(inputVariantValues, variant.split(' '))
  const animationVariant = intersect?.length ? intersect[0] : 'default'

  const wrapperVariants = InputStyles.wrapper[animationVariant]
  const labelVariants = InputStyles.label[animationVariant]
  const staticStyles = InputStyles.staticStyles[animationVariant]

  const labelText = !labelMsg && `${label}${required ? '*' : ''}`
  const hasLabel = labelMsg || label

  const wrapperAnimation = wrapperVariants && wrapperVariants[animate]
  const labelAnimation = labelVariants && labelVariants[animate]

  return (
    <View style={staticStyles.wrapper} variant={variant} debug={debug}>
      <Touchable
        style={[staticStyles.innerWrapper, wrapperAnimation, style]}
        onHover={setHover}
        onPress={() => handlePress()}
        pointerEvents={'auto'}
        variant={'column'}
        gaLabel={gaLabel}
        disabled={disabled}
        gaAction={gaAction || labelText}
      >
        {hasLabel && (
          <View variant={'fullWidth'} style={labelAnimation}>
            <Text style={staticStyles.label} msg={labelMsg} text={labelText}/>
          </View>
        )}
        <InputElement
          ref={inputRef || input}
          type={type || 'text'}
          style={{ ...staticStyles.input, ...textStyle }}
          // css={[staticStyles.input, textStyle]}
          onChange={(text) => handleChange(text)}
          value={value}
          disabled={disabled}
          onFocus={() => handleFocus()}
          onBlur={handleBlur}
          onKeyDown={onKeyDown}
          rows={4}
          {...props}
        />
      </Touchable>
      {animate == 'invalid' && (
        <Text
          style={staticStyles.validatorText}
          text={validatorMessage || validateError}
        >
        </Text>
      )}
    </View>
  )
}

export default Input
