import { Theme, Settings, colorHashMap } from 'app'
import { onMount, url } from 'lib'
import callsites from 'callsites'
import queryString from 'query-string'
import { includes } from 'lodash'
import { useUserAgent } from '@oieduardorabelo/use-user-agent'
import { detect } from 'detect-browser'

const modules = {
  copy,
  hexToRgb,
  matchLetterToColor,
  getMultipartFileUploadFormData,
  flatten,
  rejectProps,
  setQueryString,
  getQueryString,
  getIntersection,
  getAssetFiletype,
  ellipsis,
  parseSourceUrl,
  shouldReloadOnMount,
  shouldReload,
  shuffle,
  getAddressFromGeocode,
  getDistance,
  makeRandomClassId,
  convertRange,
  useLimitedPerformance,
  throttle,
  scrollTo,
  loadScript,
}

function copy(string: any) {
  return JSON.parse(JSON.stringify(string))
}

function makeRandomClassId(len?: number) {
  const length = len ?? 30
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return `element_${result}`
}

function convertRange(value: number, inRange: number[], outRange: number[]) {
  const result =
    ((value - inRange[0]) * (outRange[1] - outRange[0])) /
      (inRange[1] - inRange[0]) +
    outRange[0]
  if (result > outRange[1]) return outRange[1]
  else if (result < outRange[0]) return outRange[0]
  else return result
}

function ellipsis(string: string, length = 60) {
  return string?.length > length ? `${string?.substr(0, length)}...` : string
}

function hexToRgb(hex: string): Record<'r' | 'g' | 'b', number> | null {
  const result = (/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i).exec(hex)
  if (!result) {
    warn(
      'UTILS',
      "Hex to RGB util could not convert it's argument. It may be an invalid string.",
    )
    return null
  }
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  }
}

function matchLetterToColor(initial: string) {
  if (!initial) return '#999999'
  const lowerCaseInitial = initial.toLowerCase()
  if (lowerCaseInitial && colorHashMap.hasOwnProperty(lowerCaseInitial)) {
    return colorHashMap[lowerCaseInitial]
  } else {
    return '#999999'
  }
}

function getMultipartFileUploadFormData(data, file = null) {
  info('Building Multipart form data', { data, file })
  const uploadData = {
    data: JSON.stringify({
      ...data,
      type: 'application/json',
    }),
    file,
  }
  const formData = new FormData()
  for (const key in uploadData) {
    formData.append(key, uploadData[key])
  }
  return formData
}
function flatten(obj: any[]): any[]
function flatten(obj: object): Record<string, any>
function flatten(
  obj: any[] | Record<string, any>,
): any[] | Record<string, any> {
  if (typeof obj.flat === 'function') {
    return obj.flat()
  }

  if (Array.isArray(obj)) {
    const arr = []
    obj.forEach((value) => (Array.isArray(value) ? arr.push(flatten(value)) : arr.push(value)),
    )

    return arr
  } else if (typeof obj === 'object') {
    let object: Record<string, any> = {}

    Object.entries(obj).forEach(([key, value]) => {
      if (typeof value === 'object' && !Array.isArray(value)) {
        const newObject = {
          ...object,
          ...flatten(value as Record<string, any>),
        }
        object = newObject
      } else {
        object[key] = value
      }
    })

    return object
  }

  return obj
}

function rejectProps(props) {
  let funcName = ''
  try {
    funcName = callsites()[1].getFunctionName()
  } catch (err) {
    // pass
  }
  for (const k in props) {
    if (props[k]) {
      warn(`Prop "${k}" is not supported in <${funcName}/>`)
      // const cs = callsites()
      // for (const c in cs) {
      //   console.log('func', cs[c].getFunctionName())
      // }
    }
  }
}

function setQueryString(query) {
  const a = url()
  const next = queryString.stringifyUrl({ url: a.pathname, query })
  info({ url: a, next })
  window.history.pushState({ path: next }, Settings.WEBSITE_TITLE, next)
}

function getQueryString() {
  try {
    info({ values: queryString.parse(location.search) })
    return queryString.parse(location.search)
  } catch (err) {
    warn(err)
    return {}
  }
}

function getIntersection(arrayA = [], arrayB = []) {
  const intersect = arrayA.filter((item) => arrayB.includes(item))
  return intersect
}

const imageTypes = ['png', 'jpg', 'jpeg', 'image']
const videoTypes = ['mp4', 'video']
const pdfTypes = ['pdf']
function getAssetFiletype(asset) {
  try {
    if (asset.file) {
      let type = []
      switch (typeof asset.file) {
        case 'object':
          if (asset.file.type) {
            type = [(asset.file.type as string).split('/').pop()]
            break
          }
        case 'string':
          type = [(asset.file as string).split('.').pop()]
      }

      if (getIntersection(type, imageTypes).length) return 'image'
      if (getIntersection(type, videoTypes).length) return 'video'
      if (getIntersection(type, pdfTypes).length) return 'pdf'
    }
  } catch (e) {
    warn('Error on getAssetFileType ', e)
  }
  warn('getAssetFileType could not find assset file type for ', asset)
  return null
}
type ParseSourceUrlArg = {
  source?: string
  src?: string
}
function parseSourceUrl(args: string): string
function parseSourceUrl(args: ParseSourceUrlArg | string): string {
  if (!args) return null

  let res = ''
  let address = ''
  if (typeof args === 'string') {
    address = args
  } else {
    address = args.source || args.src || ''
  }

  if (address && address.startsWith('/media/')) {
    const tmp = address.substr(1, address.length)
    res = `${Settings.BASE_URL}${tmp}`
  } else if (address) {
    res = address
  } else {
    res = `https://picsum.photos/600?random=${Math.random() * 100}`
  }
  return res
}

function shouldReload() {
  const { reload } = getQueryString()
  if (reload && reload == 'true') {
    setQueryString({ reload: undefined })
    location.reload()
  }
}

function shouldReloadOnMount() {
  onMount(() => {
    shouldReload()
  })
}

function shuffle<T extends unknown>(array: T[]): T[] {
  // Makes array random
  const shuffled = [...array]
  let currentIndex = array.length,
    temporaryValue,
    randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = shuffled[currentIndex]
    shuffled[currentIndex] = shuffled[randomIndex]
    shuffled[randomIndex] = temporaryValue
  }

  return shuffled
}

function getAddressFromGeocode(geocodeResults) {
  if (geocodeResults.length) {
    const city = geocodeResults[0].address_components.find((o) => includes(o.types, 'administrative_area_level_2'),
    )
    const state = geocodeResults[0].address_components.find((o) => includes(o.types, 'administrative_area_level_1'),
    )
    const country = geocodeResults[0].address_components.find((o) => includes(o.types, 'country'),
    )
    return {
      city: city.long_name,
      state: state.short_name,
      country: country.long_name,
    }
  }
  return null
}

type coords = { lat: number, lng: number }
function getDistance(coords1: coords, coords2: coords) {
  const R = 6371 // Radius of the earth in km
  const dLat = deg2rad(coords2.lat - coords1.lat)
  const dLon = deg2rad(coords2.lng - coords1.lng)
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(coords1.lat)) * Math.cos(deg2rad(coords2.lat)) * Math.sin(dLon/2) * Math.sin(dLon/2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))
  const d = R * c
  return d // distance in KM
}

function deg2rad(deg) {
  return deg * (Math.PI/180)
}

function useLimitedPerformance() {
  const { width } = Theme.hooks.size()
  const initialBrowser = detect()
  let uastring = ''
  try {
    uastring = window.navigator.userAgent
  } catch (err) {
    uastring = ''
  }
  const userAgent = useUserAgent(uastring)
  if (width > 2560) {
    return 1
  } else if (width < 900) {
    const platformName =
      (userAgent?.os?.name || initialBrowser?.os)?.toLowerCase() || ''
    if (platformName.includes('ios')) {
      return 2
    } else {
      return 3
    }
  } else {
    return 0
  }
}

let timerId
// Throttle function: Input as function which needs to be throttled and delay is the time interval in milliseconds
function throttle(func, delay) {
  // If setTimeout is already scheduled, no need to do anything
  if (timerId) {
    return
  }

  // Schedule a setTimeout after delay seconds
  timerId = setTimeout(function () {
    func()

    // Once setTimeout function execution is finished, timerId = undefined so that in <br>
    // the next scroll event function execution can be scheduled by the setTimeout
    timerId = undefined
  }, delay)
}

function easeInOutQuad(
  current: number,
  start: number,
  change: number,
  duration: number,
) {
  const timeByHalfOfDuration = current / (duration / 2)
  if (timeByHalfOfDuration < 1) {
    return (change / 2) * current * current + start
  }
  current--
  return (-change / 2) * (current * (current - 2) - 1) + start
}

function scrollTo(target: string, duration: number) {
  const elementToScroll = document.getElementById(target)
  if (elementToScroll) {
    elementToScroll.scrollIntoView({ behavior: 'smooth' })
    window.location.hash = target
  }
}

function loadScript(loadUrl, callback = () => null) {
  const script = document.createElement('script')
  script.type = 'text/javascript'

  if ((script as unknown as { readyState: string }).readyState) {
    //IE
    const copy = script as unknown as HTMLScriptElement & {
      readyState: any
      onreadystatechange: EventListener
    }
    copy.onreadystatechange = function () {
      if (copy.readyState == 'loaded' || copy.readyState == 'complete') {
        copy.onreadystatechange = null
        return callback()
      }
    }
  } else {
    //Others
    script.onload = function () {
      callback()
    }
  }

  script.src = loadUrl
  document.getElementsByTagName('head')[0].appendChild(script)
}
global.Tools = modules

// Set '"noErrorTruncation":true' in tsconfig.json to view the complete definition, then copy and apply it to Tools in
// /types/custom.d.ts. Unfortunately, there is not a better way to do this while keeping intellisense in JS files.

// I recommend setting "noErrorTruncation" back to false after you update the global type, otherwise your IDE may face performance issues.
type ToolsType = typeof modules

export default modules
