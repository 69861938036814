import { Marker } from '@react-google-maps/api'
import { React } from 'lib'
import { MarkerProps } from '.'
import originPin from 'assets/originPin.png'
import { MARKER_SIZE } from './utils'

export const OriginMarker: React.FC<MarkerProps> = ({ lat, lng }) => {
  return (
    <Marker
      position={{ lat, lng }}
      icon={{
        anchor: new window.google.maps.Point(15, 15),
        url: originPin,
        scaledSize: {
          height: MARKER_SIZE.origin,
          width: MARKER_SIZE.origin,
          equals: null,
        },
      }}
    />
  )
}
