
export const POLYLINE_WEIGHT = {
  mobile: {
    becon: {
      primary: 8,
      secondary: 2,
    },
    inactive: {
      primary: 10,
      secondary: 3,
    },
    route: {
      primary: 10,
      secondary: 3,
    },
  },
  
  default: {
    becon: {
      primary: 12,
      secondary: 4,
    },
    inactive: {
      primary: 14,
      secondary: 6,
    },
    route: {
      primary: 14,
      secondary: 6,
    },
  }
}
