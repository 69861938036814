/* eslint no-restricted-imports: 'off' */
import { Session } from 'actions'
import { RootState } from 'actions/reducers'
import { useEffect, useRef, useState } from 'react'
import {
  shallowEqual,
  useSelector as originalUseSelector,
  TypedUseSelectorHook,
} from 'react-redux'
import { useMount, useUnmount } from 'react-use'
export type UseSelector = TypedUseSelectorHook<RootState>

export const useSelector = <TSelected = unknown>(
  getter: (state: RootState) => TSelected,
): TSelected => originalUseSelector(getter, shallowEqual)

/**
 * useRedux()
 * Hook to listen to store changes
 *
 * @prop {string} objectRef ---> Eg. Session.profileId -> Perform automatic queries based on obj string
 * @prop {function} query ---> Use a custom query function to listen to store changes
 */

function selectorFactory(items: string[]) {
  return (storeData) => {
    const [first, ...mapWith] = items
    let val = storeData[first]

    for (const selector of mapWith) {
      if (storeData[selector]) {
        val = storeData[selector]
      } else {
        error(
          'Incorrect selector',
          `Path "${items.join('.')}" maps to no values in store`,
        )
        return []
      }
    }
    return val
  }
}

type ReduxQuery<T> = (value: T) => unknown

export function useRedux(objectRef: string, query = null) {
  const arr = objectRef.split('.')

  if (arr.length < 1) {
    warn('No params sent to useRedux')
  } else if (arr.length >= 5) {
    warn('Too many nested objects in useRedux')
  }
  const storeData = useSelector(selectorFactory(arr))

  if (typeof query == 'function') {
    return query(storeData)
  } else if (typeof query === 'number') {
    return extractObjectById(storeData, query)
  }
  return storeData
}

const extractObjectById = (arr, id) => {
  if (arr && id) {
    const objs = arr.filter((i) => i.id == id)
    if (!objs.length) {
      return []
    } else if (objs.length == 1) {
      return objs[0]
    } else {
      warn('Multiple objects found in useRedux! Duplicate data may exist.')
      return objs[0]
    }
  } else {
    return arr
  }
}

export const onMount = useMount
export const onUnmount = useUnmount

export const onUpdate = (func, listeners = null) => {
  useEffect(() => {
    return func()
  }, listeners)
}

export const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

// Debounce Hook
// https://usehooks.com/useDebounce/

export const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay], // Only re-call effect if value or delay changes
  )

  return debouncedValue
}

export function useScrollEffect(
  effect: (passed: boolean, current: number) => any,
  breakpoint: number,
) {
  function handleScroll() {
    // log([breakpoint, window.scrollY].toString())
    const passed = window.scrollY > breakpoint
    effect(passed, window.scrollY)
  }

  useEffect(() => {
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [breakpoint])
}

type ThemeType = 'light' | 'dark'
type useThemeHook = {
  theme: ThemeType,
  isLight: boolean,
  isDark: boolean,
  getTheme: () => ThemeType
  setTheme: (currentTheme: ThemeType) => ThemeType
}

export function useTheme(): useThemeHook {
  const { theme } = useRedux('Session')

  const isLight = theme === 'light'
  const isDark = theme === 'dark'

  return {
    theme,
    isLight,
    isDark,
    getTheme: Session.getTheme,
    setTheme: Session.setTheme,
  }
}
