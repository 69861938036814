import { combineReducers, createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { AppStatus, AppStatusActions } from './AppStatus'
import { Session, SessionActions } from './Session'
import { LiveTracking, LiveTrackingActions } from './LiveTracking'
import { Map, MapActions } from './Map'

import { Settings } from 'app'

type Actions = SessionActions | AppStatusActions | LiveTrackingActions | MapActions

const reducersMap = {
  AppStatus,
  Session,
  LiveTracking,
  Map,
}
type ReducersMap = typeof reducersMap
const allReducers = combineReducers(reducersMap)

export type RootState = {
  [Property in keyof ReducersMap]: ReturnType<ReducersMap[Property]>
}

const reducers = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = {}
  }
  return allReducers(state, action)
}

const middleware =
  Settings.IS_DEVELOPMENT && Settings.WARN_ILLEGAL_STATE_MUTATIONS
    ? [require('redux-immutable-state-invariant').default(), thunk]
    : [thunk]

export const store = createStore<RootState, Actions, {}, {}>(
  reducers,
  composeWithDevTools(applyMiddleware(...middleware)),
)

export default {
  store,
}
