import { Theme } from 'app'
import { React, View } from 'lib'

export const Separator: React.FC<{ spacing: number, bottomOnly?: boolean, topOnly?: boolean }> = ({ spacing, bottomOnly, topOnly }) => {
  return <View style={[styles.separator, {
    marginTop: Theme.spacing(bottomOnly ? 0 : spacing),
    marginBottom: Theme.spacing(topOnly ? 0 : spacing),
  }]}/>
}

const styles = {
  separator: {
    width: '100%',
    border: `1px solid ${Theme.colors.greyscale6}`,
  },
}
