import { Polyline } from '@react-google-maps/api'
import { Theme } from 'app'
import { React } from 'lib'
import { POLYLINE_WEIGHT } from './weight'

type PolylineProps = {mapMarkers: {lat: number, lng: number}[]}

export const InactivePolyline = (props: PolylineProps) => {
  const { mapMarkers } = props

  const isMobile = Theme.hooks.down('small')

  const weight = POLYLINE_WEIGHT[isMobile ? 'mobile' : 'default'].inactive

  return (
    <>
      <Polyline
        path={mapMarkers}
        options={{
          geodesic: true,
          strokeColor: Theme.colors.darkDetail,
          strokeOpacity: 1.0,
          strokeWeight: weight.primary,
        }}
      />
      <Polyline
        path={mapMarkers}
        options={{
          geodesic: true,
          strokeColor: Theme.colors.greyAlert,
          strokeOpacity: 1.0,
          strokeWeight: weight.secondary,
        }}
      />
    </>
  )
}
