/* eslint no-restricted-imports: 'off' */
import { Reducer } from 'redux'
import { RouteEvent } from 'types/ApiTypes'

type MapState = {
  map: google.maps.Map | null,
  triggerDetails: {visible: boolean, data?: RouteEvent}
  hasExpandedTrigger: boolean
}

const initialState: MapState = {
  map: null,
  triggerDetails: { visible: false, data: null },
  hasExpandedTrigger: false,
}

export const Map: Reducer<MapState, MapActions> = (
  state = initialState,
  action,
) => {
  const newState = Object.assign({}, state)
  switch (action.type) {
    case 'SET_MAP':
      newState.map = action.data
      return newState
    case 'SET_TRIGGER_DETAILS':
      newState.triggerDetails = action.data
      return newState
    case 'SET_HAS_EXPANDED_TRIGGER':
      newState.hasExpandedTrigger = action.data
      return newState
    default:
      return state
  }
}

export type MapActions =
  | {
      type: 'SET_TRIGGER_DETAILS'
      data?: MapState['triggerDetails']
    }
    | {
      type: 'SET_HAS_EXPANDED_TRIGGER'
      data?: MapState['hasExpandedTrigger']
    }
    | {
    type: 'SET_MAP'
    data?: MapState['map']
}

