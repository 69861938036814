import { InfoWindow, Marker } from '@react-google-maps/api'

import { Theme } from 'app'
import { React, View, useMemo } from 'lib'
import { LiveTracking, Map } from 'actions'

import originPin from 'assets/originPin.png'

import { RouteEvent } from 'types/ApiTypes'
import { MARKER_SIZE } from './utils'

export const AlertMarker: React.FC<{ data: RouteEvent }> = ({ data }) => {
  const { trigger, event } = LiveTracking.useTrigger(data)
  const Icon = trigger?.icon
  const isSOS = event === 'SO'
  const isSmall = Theme.hooks.down('small')

  if (!trigger) return null

  function markerPress() {
    Map.triggerPress(data, isSmall)
  }

  // const pixelOffset = useMemo(() => new google.maps.Size(0, -15), [data?.coords?.lat, data?.coords?.lng])

  return (
    <>
      <Marker
        position={{ lat: data?.coords?.lat, lng: data?.coords?.lng }}
        icon={{
          url: originPin,
          scaledSize: {
            height: MARKER_SIZE.alert,
            width: MARKER_SIZE.alert,
            equals: null,
          },
        }}
      >
        <InfoWindow
          position={{ lat: data?.coords?.lat, lng: data?.coords?.lng }}
          options={{ pixelOffset: new google.maps.Size(0, -15) }}
          onCloseClick={() => null}
        >
          <View onClick={markerPress} style={styles.marker}>
            <Icon
              size={24}
              color={isSOS ? Theme.colors.redStatus : Theme.colors.grad1}
            />
          </View>
        </InfoWindow>
      </Marker>
    </>
  )
}

const styles = {
  marker: {
    width: 42,
    height: 42,
    padding: `${Theme.spacing(1)}px !important`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    cursor: 'pointer',
  },
}
