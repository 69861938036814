import { InfoWindow, Polyline } from '@react-google-maps/api'
import { Theme } from 'app'
import { React, useState, View, Text } from 'lib'
import { POLYLINE_WEIGHT } from './weight'

type PolylineProps = {mapMarkers: {lat: number, lng: number}[]}

export const RoutePolyline = (props: PolylineProps) => {
  const { mapMarkers } = props
  const [marker, setMarker] = useState({ visible: false, lat: null, lng: null })

  function polylinePress(event: google.maps.MapMouseEvent) {
    const lat = event.latLng.lat()
    const lng = event.latLng.lng()

    setMarker(prev => ({
      visible: !prev.visible,
      lat,
      lng,
    }))
  }

  const isMobile = Theme.hooks.down('small')

  const weight = POLYLINE_WEIGHT[isMobile ? 'mobile' : 'default'].route

  return (
    <>
      {marker.visible ? (
        <InfoWindow
          position={{ lat: marker.lat, lng: marker.lng }}
          options={{ pixelOffset: new google.maps.Size(-42, 0), ariaLabel: 'plannedRoute' }}
          onCloseClick={() => null}
        >
          <View style={styles.marker}>
            <Text variant={`p2 white`} text={`Planned Route`}/>
          </View>
        </InfoWindow>
      ) : null}

      <Polyline
        path={mapMarkers}
        onClick={polylinePress}
        options={{
          geodesic: true,
          strokeColor: Theme.colors.darkDetail,
          strokeOpacity: 1.0,
          strokeWeight: weight.primary,
        }}
      />
      <Polyline
        path={mapMarkers}
        onClick={polylinePress}
        options={{
          geodesic: true,
          strokeColor: Theme.colors.grad1,
          strokeOpacity: 1.0,
          strokeWeight: weight.secondary,
        }}
      />
    </>
  )
}

const styles = {
  marker: {
    backgroundColor: Theme.colors.grad3,
    boxShadow: '1px 4px 6px rgba(0, 0, 0, 0.2)',
    padding: '8px 12px',
  },
}
