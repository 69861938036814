import { Settings } from 'app'
import { detect } from 'detect-browser'
import { useUserAgent } from '@oieduardorabelo/use-user-agent'
import { VariantsStyleSheet } from './StyleSheet'

import callsites from 'callsites'
import { CSSInterpolation } from 'types/style'

const IGNORE_VARIANT_WARNINGS = ['fadeIn']
function getStyles<T extends Record<string, VariantsStyleSheet<any>>>(
  variant: string | NestedKeys<T>[],
  styleSheets: T,
  debug = false,
  append?: string | (NestedKeys<T> | '')[],
) {
  let variants = []

  if (variant) {
    variants = typeof variant === 'string' ? variant.trim().split(' ') : variant
    if (append) {
      const appended =
        typeof append === 'string' ? append.trim().split(' ') : append
      variants.push(...appended)
    }
  }


  let component = ''
  try {
    const trace = callsites()
    const functionNames = trace.map((c) => c.getFunctionName())
    const thisFunctionIdx = functionNames.indexOf('getStyles')
    if (thisFunctionIdx !== -1) {
      component = ` Source: <${functionNames[thisFunctionIdx + 1]}/>.`
    }
  } catch (err) {
    info('Callsites not available')
  }

  const presentSheets = Object.keys(styleSheets)
  const styles = {} as Partial<Record<keyof T, CSSInterpolation[]>>
  const foundVariants: Record<string, boolean> = {}
  Object.entries(styleSheets).forEach(([sheet, ownStyles], idx) => {
    styles[sheet as keyof T] = [ownStyles.default]

    variants.forEach((variation) => {
      if (variation) {
        if (ownStyles[variation]) {
          foundVariants[variation] = true
          styles[sheet].push(ownStyles[variation])
        }

        if (
          idx === presentSheets.length - 1 &&
          !foundVariants[variation] &&
          !IGNORE_VARIANT_WARNINGS.includes(variation)
        ) {
          warn(
            'StyleSheets',
            `Variant "${variation}" not found in stylesheets "${presentSheets.join(
              ',',
            )}".${component}`,
          )
        }
      }
    })
  })
  return styles
}

function normalizeArrayVariants<T = string>(variant: string | T[]) {
  if (Array.isArray(variant)) {
    return variant.join(' ')
  }
  return variant
}

// const getStyles = (variant, styleSheets, debug = false) => {
//   variant = variant && variant.trim().split(" ")
//   const result = {}
//   for (const s in styleSheets) {
//     result[s] = [styleSheets[s].default]
//     if (debug)
//       log("StyleSheets", {
//         result,
//         styleSheets,
//         "styleSheets[s]": styleSheets[s],
//       })
//     for (const v in variant) {
//       if (styleSheets[s][variant[v]]) {
//         result[s].push(styleSheets[s][variant[v]])
//       } else {
//         // let componentName = ''
//         try {
//           // componentName = ` of <${callsites()[1].getFunctionName()}/>`
//         } catch (err) {
//           warn(err)
//         }
//         // COMBAK We should at some point make sure no wrong variants are ever sent
//         // However, the warning below would be annoying as fuck at the moment
//         // info(`Could not find variant "${variant[v]}" in property "${s}"${componentName}`)
//       }
//     }
//   }
//   return result
// }

const isLowBrowserCompatibility = () => {
  const initialBrowser = detect()
  let uastring = ''
  try {
    uastring = window.navigator.userAgent
  } catch (err) {
    uastring = ''
  }
  const userAgent = useUserAgent(uastring)

  const browserName = (
    userAgent?.browser?.name || initialBrowser.name
  ).toLowerCase()
  const platformName = (userAgent?.os?.name || initialBrowser.os).toLowerCase()

  let acceptable = []

  if (platformName.includes('ios')) {
    // ios is okay
    acceptable = [
      'chrome',
      'crios',
      'fxios',
      'ios',
      'mobile safari',
      'safari',
      'firefox',
    ]
  } else if (platformName.includes('android')) {
    // android sucks
    acceptable = ['chrome']
  } else {
    // desktop is best
    acceptable = ['chrome', 'firefox', 'safari', 'opera', 'edge']
  }

  if (!Settings.IS_BROWSER || acceptable.includes(browserName)) {
    return false
  } else {
    return true
  }
}

export { getStyles, isLowBrowserCompatibility, normalizeArrayVariants }
