import StyleSheet from '../../lib/utils/StyleSheet'
import { Theme } from 'app'
import TextStyles, { compatibilityObject, textObject } from './TextStyles'

const commonPlaintextStyles = {
  ...textObject.default,
  ...textObject.inline,
  ...textObject.light,
  marginBottom: Theme.spacing(1),
  marginTop: Theme.spacing(2),
}

const headerPlaintextStyles = {
  ...textObject.default,
  ...textObject.inline,

  ...textObject.light,
  marginBottom: Theme.spacing(2),
  marginTop: Theme.spacing(3),
}

const plainText = {
  ...textObject.default,
  ...compatibilityObject.p1,
  ...textObject.p1,
  ...textObject.greyscale2,

  [Theme.media.down('mid')]: {
    ...textObject.p2,
  },

  '& h1': {
    ...commonPlaintextStyles,
    ...headerPlaintextStyles,
    ...compatibilityObject.h1,
    ...textObject.h1,
    ...textObject.primary,
    ...textObject.bold,
    textDecorationLine: 'none !important',
    textDecorationStyle: 'none !important',
  },
  '& h2': {
    ...commonPlaintextStyles,
    ...headerPlaintextStyles,
    ...compatibilityObject.h2,
    ...textObject.h2,
    ...textObject.primary,
    ...textObject.bold,
    textDecorationLine: 'none !important',
    textDecorationStyle: 'none !important',
  },
  '& h3': {
    ...commonPlaintextStyles,
    ...headerPlaintextStyles,
    ...compatibilityObject.h3,
    ...textObject.h3,
    ...textObject.bold,
  },
  '& h4': {
    ...commonPlaintextStyles,
    ...headerPlaintextStyles,
    ...compatibilityObject.h4,
    ...textObject.h4,
    ...textObject.bold,
    ...textObject.greyscale2,
  },
  '& h5': {
    ...commonPlaintextStyles,
    ...compatibilityObject.h5,
    ...textObject.h5,
    ...textObject.bold,
  },
  '& h6': {
    ...commonPlaintextStyles,
    ...compatibilityObject.h6,
    ...textObject.h6,
  },
  '& p': {
    ...commonPlaintextStyles,
    ...compatibilityObject.p1,
    ...textObject.p1,
    ...textObject.greyscale2,
    lineHeight: '1.6 !important',
    // marginTop: 0,
    [Theme.media.down('mid')]: {
      ...textObject.p2,
    },
  },
  '& a': {
    ...commonPlaintextStyles,
    ...compatibilityObject.p1,
    ...textObject.p1,
    ...textObject.link,
    lineHeight: '1.6 !important',
    // marginTop: 0,
    [Theme.media.down('mid')]: {
      ...textObject.p2,
    },
    // textDecorationWidth: 1,
    // backgroundColor: 'blue',
    color: Theme.colors.grad1,
    fontWeight: 'bold',
    textDecorationStyle: 'solid',
    textDecorationColor: `${Theme.colors.greyscale4}`,
    textDecorationLine: 'underline',
    '&:hover': {
      textDecorationColor: `${Theme.colors.grad1}`,
      // transform: 'translateX(12px)',
    },
  },
  '& .alignTop': {
    a: {
      verticalAlign: 'top',
      marginTop: 0,
      marginBottom: 0,
      [Theme.media.down('mid')]: {
        verticalAlign: 'top',
      },
    },
  },
  '& p > a': {
    marginTop: 0,
  },
  '& a > h4': {
    marginTop: 0,
    marginBottom: 0,
    textDecorationStyle: 'solid',
    textDecorationColor: `${Theme.colors.greyscale4}`,
    textDecorationLine: 'underline',
    '&:hover': {
      textDecorationColor: `${Theme.colors.grad1}`,
    },
  },
  '& .inline': {
    ...TextStyles.text.inline,
  },
  'table, td, th': {
    border: '1px solid #ddd',
    borderCollapse: 'collapse',
    padding: Theme.spacing(1),
    [Theme.media.down('mid')]: {
      ...textObject.p2,
    },
    th: {
      fontWeight: 'bold',
    },
  },
  table: {
    [Theme.media.down('mid')]: {
      ...textObject.p2,
    },
    marginTop: Theme.spacing(2),
    marginBottom: Theme.spacing(2),
  },
  span: {
    content: "''",
    display: 'block',
    height: '75px',
    marginTop: '-75px',
    visibility: 'hidden',
  },
  '& .scrollable': {
    overflowX: 'auto',
    overflowY: 'auto',
    maxWidth: `calc(100vw - ${Theme.spacing(4)}px)`,
  },
  li: {
    marginBottom: Theme.spacing(2),
    // alignItems: 'center',
    // display: 'flex',
  },
  '& .h4': {
    '::marker': {
      ...textObject.h4,
      fontWeight: 'bold',
    },
  },
  '& li > a': {
    marginBottom: 0,
  },
  '.allCaps': {
    textTransform: 'uppercase',
  },
  blockquote: {
    marginLeft: 0,
    padding: '16px',
    paddingBottom: '0',
    background: '#eee',
    borderRadius: '12px',
    width: '100%',
  },
}

export default {
  plainText,
}
