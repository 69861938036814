import { Theme } from 'app'
import ReactPlayer from 'react-player'
import { AppStoreButton, PageTitle, PlayStoreButton } from 'components'
import { React, CenterWrapper, StyleSheet, Text, View, Image } from 'lib'

import promotionalImage from 'assets/promotional.png'
import becon_plus from 'assets/becon+.png'

interface BeconPlusProps {
  image: 'beconPlus' | 'promotional',
  imageSize: 'large' | 'small',
}

const images = {
  beconPlus: becon_plus,
  promotional: promotionalImage,
}

export const BeconPlusStore: React.FC<BeconPlusProps> = ({ image, imageSize = 'small' }) => {
  return (
    <CenterWrapper style={image !== 'promotional' && styles.centerWrapper}>
      <View style={[styles.downloadWrapper, image !== 'promotional' && styles.reversedWrapper]}>
        {image !== 'promotional' && (
          <View style={styles.video}>
            <ReactPlayer
              controls
              width='100%'
              url='https://vimeo.com/802856307'
            />
          </View>
        )}
        <View style={styles.innerWrapper2}>
          <PageTitle
            location='Try BECON+ For Free'
            style={styles.text}
          />
          <Text
            variant={`p2 marginTop:3`} style={styles.text}
            text={`Take full control of your safety and give yourself peace-of-mind in all of life’s journeys, activities and adventures.`}
          />
          <View variant={['flexNoFill']} style={styles.buttonWrapper}>
            <AppStoreButton/>
            <PlayStoreButton/>
          </View>
        </View>
        {image === 'promotional' && (
          <Image source={images[image]} style={[styles.appImage, imageSize === 'large' ? styles.appImageLargeist : styles.appImageSmall]}/>
        )}
      </View>
    </CenterWrapper>
  )
}

const styles = StyleSheet.createComponentStyleSheet({
  centerWrapper: {
    [Theme.media.up('mid')]: {
      marginTop: -Theme.spacing(20),
    },
    [Theme.media.down('mid')]: {
      marginTop: -Theme.spacing(10),
    },
  },
  reversedWrapper: {
    flexDirection: 'row-reverse',
  },
  downloadWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: Theme.spacing(20),
    [Theme.media.down('largeish')]: {
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: Theme.spacing(10),
    },
  },
  innerWrapper2: {
    display: 'flex',
    flexDirection: 'column',
    [Theme.media.down('largeish')]: {
      alignItems: 'center',
    },
  },
  text: {
    flexGrow: 0,
    [Theme.media.down('largeish')]: {
      textAlign: 'center',
      alignItems: 'center',
    },
  },
  buttonWrapper: {
    marginTop: Theme.spacing(6),
    gap: Theme.spacing(2),
    [Theme.media.down('largeish')]: {
      ...Theme.center,
    },
    [Theme.media.down('tiny')]: {
      marginTop: Theme.spacing(2),
      flexDirection: 'column',
      ...Theme.center,
    },
  },
  video: {
    width: '100%',
    [Theme.media.down('largeish')]: {
      marginBottom: Theme.spacing(10),
    },
    [Theme.media.down('small')]: {
      marginBottom: Theme.spacing(4,
      ),
    },
  },
  appImage: {
    width: '100%',
    [Theme.media.down('largeish')]: {
      marginTop: Theme.spacing(10),
    },
    [Theme.media.down('small')]: {
      marginTop: Theme.spacing(10),
    },
  },
  appImageSmall: {
    maxWidth: 400,
  },
  appImageLargeist: {
    maxWidth: 800,
  },
})
