/* eslint no-restricted-imports: 'off' */
// import { LiveTracking  as inspectorLiveTracking} from "inspector"
import { Reducer } from 'redux'
import { Profile, Coords, RouteEvent } from 'types/ApiTypes'

type LiveTrackingState = {
  profile: Profile;
  route: any;
  userRoute: Coords[]
  currentLocation: Partial<Coords>
  routeEvents: RouteEvent[]
}

const initialState: LiveTrackingState = {
  profile: {},
  route: {},
  userRoute: [],
  currentLocation: {},
  routeEvents: [],
}

export const LiveTracking: Reducer<LiveTrackingState, LiveTrackingActions> = (
  state = initialState,
  action,
) => {
  const newState = Object.assign({}, state)
  switch (action.type) {
    case 'SET_PROFILE_DATA':
      newState.profile = action.data
      return newState
    case 'SET_ROUTE_DATA':
      newState.route = action.data
      return newState
    case 'SET_CURRENT_LOCATION':
      newState.currentLocation = action.data
      return newState
    case 'SET_USER_ROUTE':
      newState.userRoute = action.data
      return newState
    case 'SET_ROUTE_EVENTS':
      newState.routeEvents = action.data
      return newState
    default:
      return state
  }
}

export type LiveTrackingActions =
  | {
      type: 'SET_PROFILE_DATA'
      data?: LiveTrackingState['profile']
    }
  | {
    type: 'SET_ROUTE_DATA'
    data?: LiveTrackingState['route']
    }
  | {
       type: 'SET_USER_ROUTE'
       data?: LiveTrackingState['userRoute']
    }
  | {
      type: 'SET_CURRENT_LOCATION'
      data?: LiveTrackingState['currentLocation']
    }
  | {
      type: 'SET_ROUTE_EVENTS'
      data?: LiveTrackingState['routeEvents']
    }

