import { GoogleMap } from '@react-google-maps/api'
import { store } from 'actions/reducers'
import { React } from 'lib'
import { Coords, RouteEvent } from 'types/ApiTypes'

const MAP_PADDING = {
  small: 100,
  default: 250,
}

const MAP_ZOOM = {
  value: {
    mobile: 17,
    default: 17,
  },
  max: 22,
  min: 10,
}

function setMap(map: google.maps.Map) {
  store.dispatch({ type: 'SET_MAP', data: map })
}

function setTriggerDetails(visible = false, data: RouteEvent = null) {
  store.dispatch({ type: 'SET_TRIGGER_DETAILS', data: { visible, data } })
}

function setHasExpandedTrigger(visible = false) {
  store.dispatch({ type: 'SET_HAS_EXPANDED_TRIGGER', data: visible })
}

function triggerPress(data: RouteEvent, isSmall = false) {
  const triggerDetails = store.getState().Map.triggerDetails

  if (data?.created_datetime !== triggerDetails?.data?.created_datetime) {
    if (isSmall) {
      setTriggerDetails(true, data)
      return
    } // change but dont close

    //closes and then opens different trigger
    setTriggerDetails(false, triggerDetails?.data)
    setTimeout(() => setTriggerDetails(true, data), 500)
  } else {
    setTriggerDetails(!triggerDetails?.visible, data)
  }
}

function clear(prevTrigger: RouteEvent, onSuccess?: () => void) {
  setTriggerDetails(false, prevTrigger)
  setTimeout(() => {
    setTriggerDetails(false)
    onSuccess?.()
  }, 500)
}

const getMarkers = (polyEvents: Array<{ coords: Coords }>) => {
  const markers = []

  if (polyEvents.length >= 1) {
    polyEvents.forEach(data => {
      if (data?.coords?.lat && data?.coords?.lng) {
        markers.push({ lat: data.coords.lat, lng: data.coords.lng })
      }
    })
  }

  return markers
}

const getMarkersCoords = (): Array<{ lat: number; lng: number }> => {
  const { userRoute, routeEvents, route, currentLocation } = store.getState().LiveTracking

  // get all Map Markers positions
  let routeMarkers = []

  if (route.mode === 'ST') {
    routeMarkers = [
      { lat: route?.destination?.coords?.lat, lng: route?.destination?.coords?.lng },
      { lat: route?.origin?.coords?.lat, lng: route?.origin?.coords?.lng },
      ...route?.coords,
    ]
  }

  const routeEventsMarkers = getMarkers(routeEvents)
  const userMarkers = getMarkers(userRoute)

  const markers = [
    currentLocation,
    ...routeMarkers,
    ...routeEventsMarkers,
    ...userMarkers,
  ]

  return markers
}

type FitToMarkers = { isSmall?: boolean, map: google.maps.Map }
function fitToUserLocation({ map, isSmall }: FitToMarkers) {
  const { currentLocation } = store.getState().LiveTracking

  map?.moveCamera({
    center: {
      lat: currentLocation?.lat,
      lng: currentLocation?.lng,
    },
    zoom: MAP_ZOOM.value[isSmall ? 'mobile' : 'default'],
  })
}

export default {
  setMap,
  MAP_ZOOM,
  setTriggerDetails,
  triggerPress,
  clear,
  fitToUserLocation,
  setHasExpandedTrigger,
}
