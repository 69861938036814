import StyleSheet from '../../lib/utils/StyleSheet'
import { Theme, ViewStyles } from '../index'
import { textObject } from './TextStyles'

const wrapper = StyleSheet.createVariants({
  ...ViewStyles,
  default: {
    background: Theme.colors.grad1,

    animation: Theme.animations().spin,
    transition: 'all 0.3s ease',
    borderRadius: Theme.values.borderRadius,
    display: 'inline-flex',
    fontSize: '1.125rem',
    // fontSize: 'calc((1vw+1.6px)*1.125)',
    whiteSpace: 'nowrap',
    ...Theme.paddingHorizontal(6),
    ...Theme.paddingVertical(1),
    boxSizing: 'border-box',
    '&:hover': {
      background: Theme.colors.grad2,
    },
    '&:active': {
      transform: 'scale(0.9)',
    },
  },
  bgGrey: {
    backgroundColor: Theme.colors.greyscale6,
    '&:hover': {
      backgroundColor: Theme.colors.greyscale6,
      opacity: 0.8,
    },
    '&:active': {
      transform: 'scale(0.9)',
    },
  },
  small: {
    padding: Theme.spacing(1),
  },
  responsive: {
    [Theme.media.down('mid')]: {
      padding: Theme.spacing(1),
      paddingHorizontal: Theme.spacing(3),
      whiteSpace: 'break-spaces',
      textAlign: 'center',
    },
  },
  gradient: {
    background: Theme.colors.gradientFollo,
    backgroundSize: '100% 100%',
    '&:hover': {
      background: Theme.colors.gradientFollo,
    },
    '&:active': {
      transform: 'scale(0.9)',
    },
  },
  noHover: {
    '&:hover': {
      background: 'none',
    },
  },

  fullWidth: {
    // flex: 1,
    width: '100%',
    ...Theme.center,
  },
  link: {
    background: 'transparent',
  },

  disabled: {
    background: Theme.colors.grad4,
    opacity: 0.6,
    '&:hover': {
      background: Theme.colors.grad4,
      opacity: 0.6,
    },
  },
  spacing: {
    paddingLeft: Theme.spacing(4),
    paddingRight: Theme.spacing(4),
  },
  round: {
    borderRadius: '1000px',
  },
  noDecoration: {
    textDecorationLine: 'none !important',
  },
  debug: {
    ...Theme.debug('rgb(0, 255, 0)'),
  },
})

const text = StyleSheet.createVariants({
  default: {
    ...textObject.p1,
    // fontSize: 18,
    fontWeight: 700,
    letterSpacing: 1.3,
    transition: '300ms',
  },
  small: {
    // ...TextStyles.text.p2,
  },
  responsive: {
    [Theme.media.down('mid')]: {
      ...textObject.p2,
    },
  },
  inverted: {
    color: 'black',
  },
  white: {
    color: 'white',
  },
  dark: {
    color: Theme.colors.greyscale1,
  },

  center: {
    ...Theme.center,
  },
  bold: {
    fontWeight: 'bold',
  },
  link: {
    color: 'black',
    padding: Theme.spacing(1),
    paddingLeft: 0,
    paddingRight: 0,
    fontWeight: 'normal',
    // ...TextStyles.text.default,
    // ...TextStyles.text.link,
  },
  smallText: {
    fontSize: 16,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
})

const variants = { wrapper, text }
export default variants
