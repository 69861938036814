import { Theme } from 'app'
import ReactPlayer from 'react-player'
import { React, StyleSheet, View } from 'lib'

export const VideoComponent = ({ post }) => {
  return (
    <>
      <View style={styles.wrapper}>
        <ReactPlayer
          controls
          width='100%'
          height='50vh'
          url={post?.video}
          style={styles.video}
        />
      </View>
    </>
  )
}

const styles = StyleSheet.createComponentStyleSheet({
  wrapper: {
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    gap: Theme.spacing(3),
  },
  item: {
    margin: '0 !important',
    textDecoration: 'none !important',
  },
  itemWrapper: {
    minHeight: 220,
    borderRadius: Theme.spacing(2.3),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Theme.colors.greyscale5,
    overflow: 'hidden',
    [Theme.media.down('small')]: {
      flexDirection: 'column',
    },
  },
  video: {
    width: '100%',
    borderRadius: Theme.values.borderRadiusMedium,
    background: 'linear-gradient(180deg, rgba(230,217,239,1) 0%, rgba(207,192,245,1) 100%)',
    [Theme.media.down('largeish')]: {
      marginBottom: Theme.spacing(10),
    },
    [Theme.media.down('small')]: {
      marginBottom: Theme.spacing(4),
    },
  },
  imageWrapper: {
    position: 'relative',
    backgroundColor: Theme.colors.greyscale1,
    width: '100%',
    height: 'auto',
    flexShrink: 0,
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    [Theme.media.up('small')]: {
      maxWidth: 220,
    },
    [Theme.media.down('small')]: {
      minHeight: 330,
    },
  },
  contentWrapper: {
    paddingLeft: Theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
