import { keyframes } from '@emotion/react'

export const breakpoints = {
  zero: 0, // where it all begins
  tinyest: 290, // for small adjustment
  tiny: 380, // for small adjustment
  smallish: 420, // for small adjustment
  small: 600, // important
  mid: 900, // important
  largeish: 1050, // for small adjustment
  large: 1200, // important
  xlarge: 1400, // for small adjustment
  xxlarge: 1800, // important
  xxxlarge: 2000, // for small adjustment
  huge: 2559, // for small adjustment
} as const
export const themeValues = {
  spacing: 8,
  headerBannerHeight: 0,
  // headerBannerHeight: 50,
  headerMenuHeight: 'max(50px, min(calc(16px + 3vw), 64px))',
  headerMenuMargin: 'calc(max(50px, min(calc(16px + 3vw), 64px)) + 2*8px)',
  maxHeaderMenuHeight: 60,
  maxContentWidth: 1280,
  maxTextContentWidth: 980,
  footerHeight: 540,
  borderRadius: 20,
  borderRadiusMedium: 18,
  borderRadiusLarge: 28,
  borderRadiusSmall: 10,
  isSocialBrowser: typeof window != 'undefined' ? window.screen.width < window.innerWidth : null,
} as const
export const themeKeyframes = {
  spin: keyframes`
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    `,
} as const

export const colors = {
  gradientFollo:
    'linear-gradient(62.41deg, #6956FF 2.18%, #755DEF 16.01%, #9470C4 39.9%, #C58F80 64.62%, #FFB331 82.64%);',

  gradientLinear:
    'linear-gradient(89.93deg, #6956FF 8.06%, #755DEF 19.09%, #9470C4 39.3%, #C58F80 68.71%, #FFB331 99.95%);',
  gradientBorder: `linear-gradient(180deg, #6956FF, #FFB331) border-box`,
  gradientBannerDark: 'linear-gradient(93.03deg, #262535 -24.14%, #605B81 100%)',
  gradientBannerLight: 'linear-gradient(95.14deg, #F0ECFF 4.66%, #FFFFFF 97.47%)',
  grad1: '#6956FF',
  grad2: '#4e3fc1;',
  grad3: '#7068B3',
  grad4: '#C58F80',
  greyscale1: '#262535',
  greyscale2: '#3C3B4D',
  greyscale3: '#7a7887',
  greyscale4: '#C5C3CD',
  greyscale5: '#D1CFDB',
  greyscale6: '#EFEEF3',
  greyscale7: '#efeff3',
  greyscale8: '#eeeff2',
  greyscale9: '#e6e9ec',
  greyscale10: '#262535',
  greyscale11: '#0B0919',
  greyscale12: '#686575',
  greyscale13: '#9297a0',
  greyAlert: '#A0A0A0',
  white: '#FFFFFF',
  black: '#000',
  star: '#FFB331',
  yellowAlert: '#FBA31E',
  ice: '#FAFAFC',
  lightPurple: '#E2DDFF',
  greenStatus: '#00D66F',
  redStatus: '#FE3938',
  lightDetail: '#B8ADF0',
  darkDetail: '#41338B',
  light: '#B0B0B0',
  veryLight: '#D9D9D9',
  mapLight: '#e3e2ee',
  mapDark: '#201f2d',

  headerGradient: 'linear-gradient(180deg, #00000066 0%, rgba(0, 0, 0, 0) 100%);',
  footerGradient: 'linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 40%);',
} as const

export const themeFonts = {
  fontFamily: 'Quicksand, sans-serif',
} as const

export const animationDefaults = {
  spin: `${themeKeyframes.spin} 4s ease infinite`,
}

export const displayTypes = {
  inline: {
    display: 'inline-block',
  },
  block: {
    display: 'block',
  },
  flex: {
    display: 'flex',
    flex: 1,
  },
  flexNoFill: {
    display: 'flex',
  },
  inlineFlex: {
    display: 'inline-flex',
  },
  absolute: {
    position: 'absolute',
  },
  relative: {
    position: 'relative',
  },
  fixed: {
    position: 'fixed',
  },
  hidden: {
    display: 'none',
  },
  grid: {
    display: 'grid',
  },
} as const

export const layout = {
  full: {
    width: '100%',
    height: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  fullHeight: {
    width: '100%',
  },
  fullView: {
    width: '100vw',
    // height: '100vh',
    height: 'calc(100vh - calc(100vh - 100%))',
  },
  fullViewWidth: {
    width: '100vw',
  },
  fullViewHeight: {
    height: 'calc(100vh - calc(100vh - 100%))',
    // height: '100vh',
  },
  whole: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  centerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  listStyles: {
    overflow: 'auto',
  },
  column: {
    flexDirection: 'column',
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  alignStart: {
    alignItems: 'flex-start',
  },
  alignEnd: {
    alignItems: 'flex-end',
  },
  alignCenter: {
    alignItems: 'center',
  },
  alignSelfStart: {
    alignSelf: 'flex-start',
  },
  alignSelfEnd: {
    alignSelf: 'flex-end',
  },
  alignSelfCenter: {
    alignSelf: 'center',
  },
  justifyStart: {
    justifyContent: 'flex-start',
  },
  justifyEnd: {
    justifyContent: 'flex-end',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  justifySpaceBetween: {
    justifyContent: 'space-between',
  },
  textRight: {
    textAlign: 'right',
  },
  textLeft: {
    textAlign: 'left',
  },
  textCenter: {
    textAlign: 'center',
  },
} as const

export const styles = {
  blur: {
    backdropFilter: 'blur(4px)',
    '-webkit-backdrop-filter': 'blur(4px)',
    transition: '500ms',
  },
  elevated: {
    boxShadow: '0px 0px 16px 16px #aaaaaa1a',
  },
  neumorphism: {
    boxShadow: '10px 10px 20px 0 #AEAEC077, -10px -10px 20px 0 #fff',
  },
  borders() {
    return {
      borderRadius: this.values.borderRadius,
    }
  },
} as const
